<template>


  <q-input stack-label label="Date range" fill-mask readonly class="my-readonly-input"
           outlined square dense hide-bottom-space
           :model-value="selectedDateRange">

    <template v-slot:append>
      <q-icon name="event" />
    </template>
    <q-popup-proxy v-model="show" class="shadow-1"
                   transition-show="flip-up" transition-hide="scale">
      <q-banner class="q-ma-none no-shadow shadow-0 q-pa-none">
        <q-card class="q-ma-none q-pa-none shadow-0">
          <q-card-section horizontal>
            <q-card-section class="q-pa-none q-pt-sm">
              <q-list dense>
                <q-item @click="setRange('today')" dense clickable>
                  <q-item-section class="">
                    <q-item-label class="no-wrap">Today</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item @click="setRange('yesterday')" dense clickable>
                  <q-item-section class="">
                    <q-item-label>Yesterday</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item @click="setRange('last7Days')" dense clickable>
                  <q-item-section class="">
                    <q-item-label class="no-wrap">Last 7 Days</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item @click="setRange('last30Days')" dense clickable>
                  <q-item-section class="">
                    <q-item-label class="no-wrap">Last 30 Days</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item @click="setRange('thisMonth')" dense clickable>
                  <q-item-section class="">
                    <q-item-label>This Month</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item @click="setRange('lastMonth')" dense clickable>
                  <q-item-section class="">
                    <q-item-label>Last Month</q-item-label>
                  </q-item-section>
                </q-item>

              </q-list>
            </q-card-section>
            <q-card-section class="q-pa-none">
              <div class="q-gutter-md row items-start">
                <q-date @range-start="onRangeStart" @range-end="onRangeEnd" class="shadow-0" ref="pickerOneRef" no-unset
                        range minimal v-model="dateRange"/>
              </div>
            </q-card-section>

          </q-card-section>
        </q-card>

      </q-banner>
    </q-popup-proxy>
  </q-input>

</template>

<script>
import {computed, ref} from "vue";
import {date} from "quasar";

export default {

  name: 'DateRangePicker',

  props: {
    modelValue: {
      required: false,
      type: [Object, null, undefined]
    },
  },

  emits: ['update:modelValue'],


  // eslint-disable-next-line
  setup(props, {emit}) {

    const pickerOneRef = ref()
    const show = ref(false)
    const pickerTwoRef = ref()
    const dateRange = ref({from: null, to: null})
    const dateSelected = ref(null)

    const selectedDateRange = computed(() => {


      console.log(dateSelected)

      if (!dateSelected.value) {
        return ""
      }

      let dt = null;
      if (typeof dateSelected.value !== 'object') {
        dt = {
          from: dateSelected.value,
          to: dateSelected.value,
        }
      } else {
        dt = dateSelected.value
      }

      emit('update:modelValue', dt)

      return dt.from + " - " + dt.to;
    })

    return {
      dateSelected,
      pickerOneRef,
      pickerTwoRef,
      dateRange,
      show,
      selectedDateRange,
      // eslint-disable-next-line no-unused-vars
      onRangeEnd(range) {
        show.value = false
        dateSelected.value = dateRange.value

      },
      // eslint-disable-next-line no-unused-vars
      onRangeStart(from) {
        dateRange.value = null;
      },

      setRange(type) {

        const timeStamp = Date.now();


        if (type === 'today') {
          const formattedString = date.formatDate(timeStamp, 'YYYY/MM/DD')
          dateRange.value = formattedString;
          dateSelected.value = {
            from: formattedString,
            to: formattedString
          };
        } else if (type === 'yesterday') {
          const formattedString = date.formatDate(date.subtractFromDate(timeStamp, {days: 1, months: 0}), 'YYYY/MM/DD')
          dateRange.value = formattedString;
          dateSelected.value = {
            from: formattedString,
            to: formattedString
          };
        } else if (type === 'last7Days') {
          dateRange.value= {
            from: date.formatDate(date.subtractFromDate(timeStamp, {days: 6, months: 0}), 'YYYY/MM/DD'),
            to: date.formatDate(timeStamp, 'YYYY/MM/DD')
          };
          dateSelected.value=dateRange.value
        }else if (type === 'last30Days') {
          dateRange.value= {
            from: date.formatDate(date.subtractFromDate(timeStamp, {days: 29, months: 0}), 'YYYY/MM/DD'),
            to: date.formatDate(timeStamp, 'YYYY/MM/DD')
          };
          dateSelected.value=dateRange.value
        }else if (type === 'thisMonth') {

          dateRange.value= {
            from: date.formatDate(date.startOfDate(timeStamp,'month'), 'YYYY/MM/DD'),
            to: date.formatDate(date.endOfDate(timeStamp,'month'), 'YYYY/MM/DD')
          };
          dateSelected.value=dateRange.value
        }else if (type === 'lastMonth') {
          const lastMonth=date.subtractFromDate(timeStamp, {days: 0, months: 1})
          dateRange.value= {
            from: date.formatDate(date.startOfDate(lastMonth,'month'), 'YYYY/MM/DD'),
            to: date.formatDate(date.endOfDate(lastMonth,'month'), 'YYYY/MM/DD')
          };
          dateSelected.value=dateRange.value
        }

        show.value = false

      }

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);

}

.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}


</style>

<template>

  <div>

    <div class="col-6 col-md-10">
      <q-input  hide-bottom-space dense="dense"
                  ref="phoneNumberInput"
                 v-model="phoneNumber"
                 outlined
                 input-class="q-ml-none q-pa-none"
                 :rules="[phoneValidator]"
                 class="q-ma-none q-pa-none"
                 @update:model-value="onChange"
      >
        <template v-slot:before>
          <q-select option-label="name" outlined hide-bottom-space input-class="text-right" dense="dense" style="min-width: 50px;" behavior="default"  @filter="filterFn" input-debounce="0" :options-dense="true" ref="countrySelect" class="ellipsis q-ma-none full-width bg-grey-2"   v-model="selectedCountry"  :options="countries" >
            <template  v-slot:selected-item="props">
<!--              <div class="q-pl-sm">{{props.opt.code+" "+props.opt.dial_code}}</div>-->
              <div class="q-pl-sm text-right">{{props.opt.dial_code}}</div>
            </template>
            <template v-slot:option="props">
              <q-item clickable @click="selectCountry(props.opt)" class="" style="cursor: pointer" dense>
                <q-tooltip>
                  {{props.opt.name}}
                </q-tooltip>
                <q-item-section top avatar>
                  <q-avatar rounded>
                    <q-icon v-if="props.selected" size="md" name="radio_button_checked"></q-icon>
                    <q-icon v-else size="md" name="radio_button_unchecked"></q-icon>
                  </q-avatar>
                </q-item-section>

                <q-item-section class="ellipsis q-pa-none q-ma-none" style="width: 80px">
                  <q-item-label class="">{{props.opt.code}} {{props.opt.dial_code}}</q-item-label>
                  <q-item-label style="font-size: 0.8em">

                    {{props.opt.name}}
                  </q-item-label>
                </q-item-section>

              </q-item>
            </template>
          </q-select>

        </template>

      </q-input>
    </div>
  </div>
</template>

<script>

import {ref,onMounted,watch } from 'vue'
import countryOptions from "@/assets/countries";
import {PhoneNumberUtil} from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

export default {

  name: 'PhoneInput',

  components: {

  },

  emits: ['update:modelValue'],

  props: {

    dense: {
      required:false,
      default:false,
      type: Boolean
    },

    modelValue: {
      required:false,
      type: [String,null,undefined]
    },

    dates: {
      required:false,
      type: [Array,null,undefined]
    }
  },


  // eslint-disable-next-line
  setup(props,{emit}) {


    const onChange=(value)=>{
      emit('update:modelValue',(selectedCountry.value.dial_code+value).replace(/\D/g, ""))
    }

    const onCountryChange=(value)=>{
      emit('update:modelValue',value?(value.dial_code+phoneNumber.value).replace(/\D/g, ""):(''+phoneNumber.value).replace(/\D/g, ""))
      phoneNumberInput.value.validate()
    }




    const phoneNumber=ref(null);

    const phoneNumberInput=ref(null);

    const countrySelect = ref(null)

    const countries = ref(countryOptions)

    const selectedCountry=ref({
      "name":"Tanzania,United Republic of Tanzania",
      "code":"TZ",
      "dial_code":"+255"
    });



    watch(
        () => selectedCountry.value,
        // eslint-disable-next-line no-unused-vars
        (newValue, oldValue) => {
          emit('update:modelValue',newValue?(newValue.dial_code+phoneNumber.value).replace(/\D/g, ""):(''+phoneNumber.value).replace(/\D/g, ""))
          phoneNumberInput.value.validate()
        },
        { deep: true }
    );

    onMounted(async () => {

        if (props.modelValue){
          const parsedNumber = phoneUtil.parseAndKeepRawInput(props.modelValue,selectedCountry.value.code);


          phoneNumber.value=phoneUtil.format(parsedNumber, PhoneNumberUtil.E164).replace(/\D/g, '');
        }

    })

    return {
      phoneNumberInput,
      onCountryChange,
      onChange,
      selectedCountry,
      countries,
      countrySelect,
      phoneNumber,
      selectCountry(option){
        countrySelect.value.toggleOption(option)
      },
      phoneValidator(val) {

        if(!val || val.length<1){
          return 'Please enter a valid phone number'
        }

        // const phoneRegex = /^(\+?255|0|^){1}?([67|22]{1}[13456789|2]{1}[0-9]{1}|[6]{1}[57]{1}[2-9]{1}[0-9]{6}){1}?([0-9]{6})$/
        // if (!val.match(phoneRegex)) {
        //   return 'Please enter a valid phone number'
        // }

        try {
          const parsedNumber = phoneUtil.parseAndKeepRawInput(val,selectedCountry.value.code);


          phoneNumber.value=phoneUtil.format(parsedNumber, PhoneNumberUtil.NATIONAL).replace(/\D/g, '');
          emit('update:modelValue',selectedCountry.value?(selectedCountry.value.dial_code+phoneNumber.value).replace(/\D/g, ""):''+phoneNumber.value.replace(/\D/g, ""))
          //
          if (phoneUtil.isValidNumber(parsedNumber) && phoneUtil.isPossibleNumber(parsedNumber)) {
            return true;
          } else {
            return 'Please enter a valid phone number'
          }
        } catch (e) {
          return 'Please enter a valid phone number'
        }

      },
      filterFn(val, update) {


        if (!val || val.name === '') {
          update(() => {
            countries.value = countryOptions

            // here you have access to "ref" which
            // is the Vue reference of the QSelect
          })
          return
        }

        update(() => {
          const needle = val.toLowerCase()
          countries.value = countryOptions.filter(v => v.name.toLowerCase().indexOf(needle) > -1)
        })},
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
:deep(.q-field__before), :deep(.q-field__prepend) {
   padding-right: 0px!important;
}
</style>

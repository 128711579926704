<template>

    <div class="full-width">
      <Transition appear name="slide-fade">
      <q-img width="100px" :src="require(`@/assets/mosquito.png`)"/>
      </Transition>
    </div>


</template>

<script>


export default {

  name: 'AnimatedMosquito',

  props: {

  },



  // eslint-disable-next-line
  setup () {

    return {

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);

}

.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
</style>

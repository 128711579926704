<template>
  <q-card ref="parentElement"  class="rounded-borders bg-white shadow-0 q-mb-sm">


    <q-card-section class="full-height">


        <div v-html="schema.text" class="col-12 col-md-3" >
        </div>


    </q-card-section>
  </q-card>
</template>

<script lang="js">


export default {

  name: 'TextView',
  components: {

  },
  props: {
    schema: {
      type: Object,
      require: true
    },
    url: String,
    headers: Array,
    withCredentials: {
      type: Boolean,
      default: false,
    }
  },

  // eslint-disable-next-line
  setup(props,{emit}) {

    return {

    }
  }

}

</script>
<template>
  <q-card v-intersection.once="onIntersection"  ref="parentElement" class="rounded-borders bg-white shadow-0 q-mb-sm">
    <q-card-section class="q-py-xs  q-pr-xs">
      <div class="row items-center no-wrap">
        <div class="col full-height">
          <q-item-label class="text-bold" style="font-size: 1.0em">{{ schema.title }}</q-item-label>
        </div>
        <div class="col-auto full-height">
          <div class="q-gutter-xs">
            <q-btn v-show="isFullScreen" @click="toggle" dense color="grey-7" flat padding="0 5px 0 5px" icon="fullscreen_exit" :disable="loading"></q-btn>
            <q-btn v-show="!isFullScreen" dense flat padding="0 5px 0 5px" icon="more_vert" :disable="loading">
              <q-menu square  transition-show="jump-down"
                       transition-hide="jump-up" auto-close>
                <q-list dense style="min-width: 100px">
                  <q-item @click="toggle" dense clickable>

                    <q-item-section>
                      <q-item-label lines="1" header class="q-ma-none q-pa-none text-black"><div><q-icon size="sm" :name="`${isFullScreen?'fullscreen_exit':'fullscreen'}`" />{{`${!isFullScreen?'View Fullscreen':'Exit Fullscreen'}`}}</div></q-item-label>
                    </q-item-section>
                  </q-item>

                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
      </div>
    </q-card-section>
    <q-separator></q-separator>
    <q-card-section v-if="error" class="full-height">
      <div :style="{'min-height':`${schema.height+'px'}`}" class="q-gutter-md row justify-center items-center flex full-height">
        <div class="q-gutter-sm text-center">
          <q-icon  name="warning" size="md" color="red" />
          <p class="justify-center text-center">
            {{ error }}
          </p>
        </div>
      </div>
    </q-card-section>
    <q-card-section v-else class="full-height q-mt-sm" style="padding: 1px 1px 1px 8px">
      <div :style="{'min-height':`${schema.height+'px'}`}" v-show="loading" class="q-gutter-md row justify-center items-center flex full-height">
          <q-spinner
              color="primary"
              size="3em"
              :thickness="5"
          ></q-spinner>
      </div>


      <div v-show="!loading" class="row q-col-gutter-x-sm q-col-gutter-y-sm full-height">


        <!--        <div :style="{'width':w,'height':'200px'}">-->
        <div :id="uniqueId" class="container" :style="{'width':'100%','height':`${isFullScreen?'100%':schema.height+'px'}`,'border':'1px solid lightgrey'}">
        </div>

      </div>

    </q-card-section>
  </q-card>
</template>

<script lang="js">

import { onMounted, ref, watch, onBeforeMount} from 'vue'
import axios from "axios";
import config from "@/config";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {Chart} from 'chart.js'


export default {

  name: 'PieChartMap',
  components: {},
  props: {
    schema: {
      type: Object,
      require: true
    },
    url: String,
    headers: Array,
    withCredentials: {
      type: Boolean,
      default: false,
    }
  },

  // eslint-disable-next-line
  setup(props, {emit}) {



    // Detect changes in fullscreen mode
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        isFullScreen.value=true;
      } else {
        isFullScreen.value=false;
      }
    });




    const searchValueByLabel = (arr, label) => {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][props.schema.labelKey] === label) {
          return arr[i][props.schema.valueKey];
        }
      }
      return 0;
    }


    const getRandomColor = () => {

      // const categoricalColors = chroma.scale(['#f8f856','#340010']).mode('lch').colors(count)

      const categoricalColors =[
        "#0065BA",
        "#661D32",
        "#888886",
        "#C00630",
        "#002F6E",
        "#1D2722",
        "#6A6463",
        "#83c2d5",
        "#66e443",
        "#c4b231",
        "#786934",
        "#63a73a",
        "#6dd5d0",
        "#c546e3",
        "#d89079",
        "#d5325c",
        "#5841d3",
        "#9d7470",
        "#dae42e",
        "#447732",
        "#c09dd6",
        "#619bdc",
        "#d07035",
        "#e23a29",
        "#442875",
        "#545983",
        "#cba7be",
        "#996db7",
        "#6b978e",
        "#b9b063",
        "#5de0ab",
        "#e03dad",
        "#cf696d",
        "#df9b2d",
        "#55d46c",
        "#824422",
        "#372241",
        "#3b3127",
        "#bb8447",
        "#a13ca9",
        "#586370",
        "#7dcb7c",
        "#6eb187",
        "#da80db",
        "#3d5534",
        "#84366d",
        "#9b64e2",
        "#c0d96f",
        "#cf6597",
        "#9fd844",
        "#b33c34",
        "#bdce8c",
        "#4f2197",

        "#cec0a3",
        "#698fa5"
      ]

      let letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        // eslint-disable-next-line no-unused-vars
        color += letters[Math.floor(Math.random() * 16)];
      }
      return categoricalColors;
    }


    const fetchData = () => {

      if (visible.value){

      if (map.value) {
        map.value.remove();
      }

      map.value = L.map(`${uniqueId.value}`).setView([props.schema.centerLat, props.schema.centerLng], props.schema.zoom)
      // Disable zoom with mouse scroll after setting the initial view
      map.value.scrollWheelZoom.disable();

      datasets.value = props.schema.datasets;

      //Create dataset query request without including query

      let datasetRequests = []

      for (let [sequenceNumber, dataset] of datasets.value.entries()) {

        let datasetRequest = {};

        datasetRequest.sequenceNumber = sequenceNumber;

        datasetRequest.indicator = {
          code: dataset.indicator.code
        };

        datasetRequest.filter = dataset.filter ? dataset.filter : []

        datasetRequests.push(datasetRequest);

      }


      // Create the legend control
      const legendControl = L.control({position: 'topright'});


      //Set to true if you want to test locally with sample data
      const testLocal = false;

      if (!testLocal) {

        loading.value = true
        error.value = null;
        axios.create({
          baseURL: config.baseUrl,
          timeout: 0,
          //Comment this when want to run as SPA
          withCredentials: true,
        })
            .post(`${props.url}`, datasetRequests, {
              headers: {}
            })

            // eslint-disable-next-line no-unused-vars
            .then(response => {


              let res = response.data;


              if (res.length > 0) {
                data.value = res[0].data;
              }

              //Get Labels
              let formattedLabels = []

              let labels = []
              for (let item of data.value) {
                labels.push(item[props.schema.labelKey])
                formattedLabels = [...new Set(labels)];
              }

              const groupedData = data.value.reduce((result, item) => {
                // Find if a group object already exists for the current category
                const groupIndex = result.findIndex(group => group.group.trim().replace(/[,“”"–—]/g, ' ').replace(/\s+/g, ' ') === item[props.schema.locationKey]);

                if (groupIndex === -1) {
                  // If not, create a new group object and push it to the result array
                  result.push({
                    group: item[props.schema.locationKey].trim().replace(/[,“”"–—]/g, ' ').replace(/\s+/g, ' '),
                    data: [item]
                  });
                } else {
                  // If exists, push the data item to the corresponding group's "data" array
                  result[groupIndex].data.push(item);
                }

                return result;
              }, []);

              L.tileLayer('https://cartodb-basemaps-b.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://carto.com">CartoDB</a> contributors'
              }).addTo(map.value);


              for (let i = 0; i < groupedData.length; i++) {

                let datum = {
                  backgroundColor: [],
                  data: []
                }

                for (let label of formattedLabels) {
                  datum.data.push(searchValueByLabel(groupedData[i].data, label))
                }

                datum.backgroundColor = getRandomColor()

                const chart = new Chart(document.createElement('canvas').getContext("2d"), {
                  type: props.schema.showDoughnut ? 'doughnut' : 'pie',
                  data: {
                    labels: formattedLabels,
                    datasets: [{
                      data: datum.data,
                      backgroundColor: datum.backgroundColor
                    }]
                  },
                  options: {
                    responsive: false,
                    maintainAspectRatio: true,
                    plugins: {
                      legend: {
                        display: false
                      }
                    },
                    animation: {
                      onComplete: function () {
                        const chartImage = new Image();
                        chartImage.src = chart.toBase64Image();

                        const location = groupedData[i].group.split(" ");


                        const marker = L.marker([location[0], location[1]], {
                          icon: L.divIcon({
                            className: 'custom-icon',
                            html: `<img src="${chartImage.src}" width="30" height="30">`
                          })
                        });


                        marker.addTo(map.value);

                        if (i == 0) {
                          const legendLabels = chart.data.labels;
                          const legendColors = chart.data.datasets[0].backgroundColor;


                          // Function to initialize the legend
                          legendControl.onAdd = function () {
                            const legendDiv = L.DomUtil.create('div', 'legend');
                            legendDiv.innerHTML += `<div style=font-weight:bold;display:inline-block;text-align:center;  line-height: 20px; /* same as height! */"> ${props.schema.legendTitle}</div><br>`

                            // Create the legend content
                            for (let i = 0; i < legendLabels.length; i++) {
                              // legendDiv.innerHTML += `<div style="display:inline-block;text-align:center;  line-height: 20px; /* same as height! */"><div style="background-color: ${legendColors[i]};width:30px;height:15px;color:blue;display:inline-block;"></div><span class="legend-label"> ${legendLabels[i]}</span></div><br>`
                              legendDiv.innerHTML += `    <div class="row text-dark">
                            <div class="col" style="background-color: ${legendColors[i]};margin: 1px">
                              <div class="row  q-gutter-lg">
                                 <div class="bg-white">
                                </div>
                                <div class="col bg-white q-pl-xs" style="">
                                 ${legendLabels[i]}
                                </div>
                              </div>
                            </div>
                          </div>`
                            }


                            // Prevent default action (zoom) on click
                            L.DomEvent.on(legendDiv, 'click', function (event) {
                              L.DomEvent.preventDefault(event);
                            });

                            // Prevent default action (zoom) on click
                            L.DomEvent.on(legendDiv, 'mousedown mouseup click dblclick', function (event) {
                              event.stopPropagation()
                            });

                            // Add a click event listener to legend labels
                            // const legendItems = legendDiv.getElementsByClassName('legend-label');
                            // for (let i = 0; i < legendItems.length; i++) {
                            //   legendItems[i].addEventListener('click', function () {
                            //
                            //   });
                            // }

                            return legendDiv;
                          };

                          // Add the legend control to the map
                          legendControl.addTo(map.value);


                        }

                      }
                    }
                  }
                });

              }

            })
            .catch(err => {


              if (err.response) {

                error.value = err.response.data.message ? err.response.data.message : 'Error occurred'


              } else if (err.request) {

                error.value = 'Failed to connect to the remote server'

              } else {
                // Anything else
                error.value = 'Failed to load data';
              }

            })
            .finally(() => loading.value = false)
      } else {


        let res = [
          {
            "indicator": {
              "code": "AGCM"
            },
            "data": [
              {
                location: "-6.173 35.70",
                label: "Kilimanjaro",
                value: 23,
              },
              {
                location: "  -6.173,,,,           35.750   ",
                label: "Kilimanjaro1",
                value: 12,
              },
              {
                location: "-6.173 35.750",
                label: "Kilimanjaro",
                value: 21,
              }
            ]
          }
        ]


        if (res.length > 0) {
          data.value = res[0].data;
        }

        //Get Labels
        let formattedLabels = []

        let labels = []
        for (let item of data.value) {
          labels.push(item[props.schema.labelKey])
          formattedLabels = [...new Set(labels)];
        }

        const groupedData = data.value.reduce((result, item) => {
          // Find if a group object already exists for the current category
          const groupIndex = result.findIndex(group => group.group.trim().replace(/[,“”"–—]/g, ' ').replace(/\s+/g, ' ') === item[props.schema.locationKey]);

          if (groupIndex === -1) {
            // If not, create a new group object and push it to the result array
            result.push({
              group: item[props.schema.locationKey].trim().replace(/[,“”"–—]/g, ' ').replace(/\s+/g, ' '),
              data: [item]
            });
          } else {
            // If exists, push the data item to the corresponding group's "data" array
            result[groupIndex].data.push(item);
          }

          return result;
        }, []);

        L.tileLayer('https://cartodb-basemaps-b.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://carto.com">CartoDB</a> contributors'
        }).addTo(map.value);


        for (let i = 0; i < groupedData.length; i++) {

          let datum = {
            backgroundColor: [],
            data: []
          }

          for (let label of formattedLabels) {
            datum.data.push(searchValueByLabel(groupedData[i].data, label))
          }

          datum.backgroundColor = getRandomColor()

          const chart = new Chart(document.createElement('canvas').getContext("2d"), {
            type: props.schema.showDoughnut ? 'doughnut' : 'pie',
            data: {
              labels: formattedLabels,
              datasets: [{
                data: datum.data,
                backgroundColor: datum.backgroundColor
              }]
            },
            options: {
              responsive: false,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: false
                }
              },
              animation: {
                onComplete: function () {
                  const chartImage = new Image();
                  chartImage.src = chart.toBase64Image();

                  const location = groupedData[i].group.split(" ");


                  const marker = L.marker([location[0], location[1]], {
                    icon: L.divIcon({
                      className: 'custom-icon',
                      html: `<img src="${chartImage.src}" width="30" height="30">`
                    })
                  });


                  marker.addTo(map.value);

                  if (i == 0) {
                    const legendLabels = chart.data.labels;
                    const legendColors = chart.data.datasets[0].backgroundColor;


                    // Function to initialize the legend
                    legendControl.onAdd = function () {
                      const legendDiv = L.DomUtil.create('div', 'legend');
                      legendDiv.innerHTML += `<div style=font-weight:bold;display:inline-block;text-align:center;  line-height: 20px; /* same as height! */"> ${props.schema.legendTitle}</div><br>`

                      // Create the legend content
                      for (let i = 0; i < legendLabels.length; i++) {
                        // legendDiv.innerHTML += `<div style="display:inline-block;text-align:center;  line-height: 20px; /* same as height! */"><div style="background-color: ${legendColors[i]};width:30px;height:15px;color:blue;display:inline-block;"></div><span class="legend-label"> ${legendLabels[i]}</span></div><br>`
                        legendDiv.innerHTML += `    <div class="row text-dark">
                            <div class="col" style="background-color: ${legendColors[i]};margin: 1px">
                              <div class="row  q-gutter-lg">
                                 <div class="bg-white">
                                </div>
                                <div class="col bg-white q-pl-xs" style="">
                                 ${legendLabels[i]}
                                </div>
                              </div>
                            </div>
                          </div>`
                      }


                      // Prevent default action (zoom) on click
                      L.DomEvent.on(legendDiv, 'click', function (event) {
                        L.DomEvent.preventDefault(event);
                      });

                      // Prevent default action (zoom) on click
                      L.DomEvent.on(legendDiv, 'mousedown mouseup click dblclick', function (event) {
                        event.stopPropagation()
                      });

                      // Add a click event listener to legend labels
                      // const legendItems = legendDiv.getElementsByClassName('legend-label');
                      // for (let i = 0; i < legendItems.length; i++) {
                      //   legendItems[i].addEventListener('click', function () {
                      //
                      //   });
                      // }

                      return legendDiv;
                    };

                    // Add the legend control to the map
                    legendControl.addTo(map.value);


                  }

                }
              }
            }
          });

        }
      }
    }
    }


    const data = ref([])

    const loading = ref(false)

    const datasets = ref(null);
    const map = ref(null);
    const isFullScreen = ref(false)
    const parentElement=ref(null)

    // Generate a unique ID for this component instance
    const uniqueId = ref('map-' + Math.random().toString(36).substring(7)+new Date().getTime());
    const error = ref(null)

    onMounted(() => {

      // map.value = L.map(`${uniqueId.value}`).setView([props.schema.centerLat, props.schema.centerLng], props.schema.zoom)
      // // Disable zoom with mouse scroll after setting the initial view
      // map.value.scrollWheelZoom.disable();

    })


    onBeforeMount(() => {
      if (map.value) {
        map.value.remove();
      }
    })




    // eslint-disable-next-line no-unused-vars
    watch(() => props.url, (newValue, oldValue) => {
      fetchData()
    });


    const visible = ref(false)
    return {
      data,
      loading,
      error,
      datasets,
      map,
      uniqueId,
      isFullScreen,
      parentElement,
      visible,
      onIntersection (entry) {
        visible.value = entry.isIntersecting
        fetchData()
      },
      toggle:() =>{
        // const element = document.documentElement; // Get the root DOM element (usually the whole page)
        const element = parentElement.value.$el; // Get the root DOM element (usually the whole page)

        if (document.fullscreenElement) {
          // If already in fullscreen, exit fullscreen
          document.exitFullscreen();
        } else {
          // If not in fullscreen, request fullscreen
          element.requestFullscreen()
              .catch(err => {
                console.error('Failed to enter fullscreen mode:', err);
              });
        }
      }
    }
  }

}

</script>

<style>

.container {
  z-index: 0;
}


.legend {
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  font-family: 'Open Sans', Helvetica, sans-serif;
  padding: 10px 14px;
  background-color: #FFFFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 300px;
  border: 1px solid grey;
}

.legend p {
  font-size: 16px;
  line-height: 24px;
}

.legend img {
  max-width: 50px;
  margin: auto;
  display: block;
}


.legend-color {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
</style>
<template>

  <q-card class="shadow-0 " style="">
    <q-card-section horizontal>
      <q-card-section class="bg-primary">

      </q-card-section>

      <q-separator vertical />

      <q-card-section class="bg-primary bg-grey-3 full-width full-height q-ma-none q-pa-none">
        <q-item class="full-width">
          <q-item-section ></q-item-section>
          <q-item-section class="text-right" side>
            <q-item-label caption>
              <span class=" text-right text-h6 text-bold">{{value}}</span>
              <br>
              <span class="">{{label}}</span>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-card-section>
    </q-card-section>
  </q-card>

</template>

<script>


import {onMounted} from "vue";

export default {

  name: 'SummaryBox',

  props: {
    label:String,
    value:String,
  },




  // eslint-disable-next-line
  setup () {

    onMounted(() => {



    })

    return {

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);

}

.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
</style>

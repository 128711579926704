<template>
  <q-card v-intersection.once="onIntersection" ref="parentElement" class="rounded-borders bg-white shadow-0 q-mb-sm ">
    <q-card-section class="q-py-xs  q-pr-xs">
      <div class="row items-center no-wrap">
        <div class="col full-height">
          <q-item-label class="text-bold" style="font-size: 1.0em">{{ schema.title }}</q-item-label>
        </div>
        <div class="col-auto full-height">
          <div class="q-gutter-xs">
            <q-btn v-show="isFullScreen" @click="toggle" dense color="grey-7" flat padding="0 5px 0 5px"
                   icon="fullscreen_exit" :disable="loading"></q-btn>
            <q-btn v-show="!isFullScreen" dense flat padding="0 5px 0 5px" icon="more_vert" :disable="loading">
              <q-menu square transition-show="jump-down"
                      transition-hide="jump-up" auto-close>
                <q-list dense style="min-width: 100px">
                  <q-item @click="toggle" dense clickable>

                    <q-item-section>
                      <q-item-label lines="1" header class="q-ma-none q-pa-none text-black">
                        <div>
                          <q-icon size="sm" :name="`${isFullScreen?'fullscreen_exit':'fullscreen'}`"/>
                          {{ `${!isFullScreen ? 'View Fullscreen' : 'Exit Fullscreen'}` }}
                        </div>
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
      </div>
    </q-card-section>
    <q-separator></q-separator>
    <q-card-section v-if="error" class="full-height">
      <div :style="{'min-height':`${schema.height+'px'}`}" class="q-gutter-md row justify-center items-center flex full-height">
        <div class="q-gutter-sm text-center">
          <q-icon name="warning" size="md" color="red"/>
          <p class="justify-center text-center">
            {{ error }}
          </p>
        </div>
      </div>
    </q-card-section>
    <q-card-section v-else class="full-height q-pa-none">
      <div :style="{'min-height':`${schema.height+'px'}`}"  v-if="loading" class="q-gutter-md row justify-center items-center flex full-height">
        <div>
          <q-spinner
              color="primary"
              size="3em"
              :thickness="5"
          ></q-spinner>
        </div>
      </div>
      <div class="full-height" v-else>
        <q-scroll-area class="" :style="{'height':`${isFullScreen?'100%':schema.height+'px'}`}">
          <table class="">
            <thead>
            <tr style="text-align: right">
              <th style=""></th>
              <th v-for="column in columns" :key="column">{{ column }}</th>
              <th>Total</th>
            </tr>
            </thead>
            <tbody>
            <tr style="text-align: right" v-for="row in rows" :key="row">
              <td>{{ row }}</td>
              <td v-for="column in columns" :key="column">
                {{ getValue(row, column) }}
              </td>
              <td>{{ getRowTotal(row) }}</td>
            </tr>
            <tr>
              <td class="text-bold">Total</td>
              <td style="text-align: right" v-for="column in columns" :key="column">
                {{ getColumnTotal(column) }}
              </td>
              <td style="text-align: right">{{ getGrandTotal() }}</td>
            </tr>
            </tbody>
          </table>
        </q-scroll-area>
      </div>
    </q-card-section>
  </q-card>
</template>

<script lang="js">

import {onMounted, ref, watch} from 'vue'
import axios from "axios";
import config from "@/config";

export default {

  name: 'PivotTable',
  components: {},

  props: {
    schema: {
      type: Object,
      require: true
    },
    url: String,
    headers: Array,
    withCredentials: {
      type: Boolean,
      default: false,
    }
  },

  // eslint-disable-next-line
  setup(props, {emit}) {


    // Detect changes in fullscreen mode
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        isFullScreen.value = true;
      } else {
        isFullScreen.value = false;
      }
    });


    const fetchData = () => {
      if (visible.value){

      datasets.value = props.schema.datasets;

      //Create dataset query request without including query

      let datasetRequests = []

      for (let [sequenceNumber, dataset] of datasets.value.entries()) {

        let datasetRequest = {};

        datasetRequest.sequenceNumber = sequenceNumber;

        datasetRequest.indicator = {
          code: dataset.indicator.code
        };

        datasetRequest.filter = dataset.filter ? dataset.filter : []

        datasetRequests.push(datasetRequest);

      }
      //Set to true if you want to test locally with sample data
      const testLocal = false;

      if (!testLocal) {

        loading.value = true
        error.value = null;

        axios.create({
          baseURL: config.baseUrl,
          timeout: 0,
          //Comment this when want to run as SPA
          withCredentials: true,
        })
            .post(`${props.url}`, datasetRequests, {
              headers: {}
            })

            // eslint-disable-next-line no-unused-vars
            .then(response => {


              let res = response.data;


              // let res = [
              //   {
              //     indicator:{
              //       code:1
              //     },
              //     data:[
              //       {
              //         ED1_tx:"An. Funestus  dhshs hdsdbsdbdshdsd d sdssd sd d sd",
              //         ED1_sts:"Fed",
              //         sum:120
              //       },
              //       {
              //         ED1_tx:"An. Gambiae",
              //         ED1_sts:"Unfed",
              //         sum:12
              //       }
              //     ]
              //   }
              // ];

              for (let [sequenceNumber, dataset] of datasets.value.entries()) {
                const result = res.find(obj => obj.indicator.code === dataset.indicator.code && obj.sequenceNumber === sequenceNumber);

                dataset.data = result.data;

                break;

              }


              //Format data
              data.value = datasets.value.length > 0 ? datasets.value[0].data : null;


              populateRowsAndColumns();


            })
            .catch(err => {

              if (err.response) {

                error.value = err.response.data.message ? err.response.data.message : 'Error occurred'


              } else if (err.request) {

                error.value = 'Failed to connect to the remote server'

              } else {
                // Anything else
                error.value = 'Failed to load data';
              }

            })
            .finally(() => loading.value = false)
      }
    }
    }

    const data = ref([])

    const datasets = ref(null);

    const loading = ref(false)

    const isFullScreen = ref(false)

    const parentElement = ref(null)
    const error = ref(null)

    onMounted(() => {


    })


    const rows = ref([])

    const columns = ref([])

    const populateRowsAndColumns = () => {

      rows.value = [...new Set(data.value.map(item => item[props.schema.rowKey]))];

      columns.value = [...new Set(data.value.map(item => item[props.schema.columnKey]))];

    }

    const getValue = (row, column) => {

      const record = data.value.find(item => item[props.schema.rowKey] === row && item[props.schema.columnKey] === column);

      return record ? record[props.schema.dataKey] : '0';

    }

    const getRowTotal = (row) => {

      const dataByRow = data.value.filter(item => item[props.schema.rowKey] === row);

      return dataByRow.reduce((total, item) => total + item[props.schema.dataKey], 0);

    }

    const getColumnTotal = (column) => {

      const populationByColumn = data.value.filter(item => item[props.schema.columnKey] === column);

      return populationByColumn.reduce((total, item) => total + item[props.schema.dataKey], 0);

    }

    const getGrandTotal = () => {

      return data.value.reduce((total, item) => total + item[props.schema.dataKey], 0);

    }

    // eslint-disable-next-line no-unused-vars
    watch(() => props.url, (newValue, oldValue) => {

      fetchData()

    });

    const visible = ref(false)

    return {
      data,
      loading,
      error,
      datasets,
      rows,
      columns,
      isFullScreen,
      parentElement,
      visible,
      onIntersection (entry) {
        visible.value = entry.isIntersecting
        fetchData()
      },
      populateRowsAndColumns,
      getValue,
      getRowTotal,
      getColumnTotal,
      getGrandTotal,

      toggle: () => {
        // const element = document.documentElement; // Get the root DOM element (usually the whole page)
        const element = parentElement.value.$el; // Get the root DOM element (usually the whole page)

        if (document.fullscreenElement) {
          // If already in fullscreen, exit fullscreen
          document.exitFullscreen();
        } else {
          // If not in fullscreen, request fullscreen
          element.requestFullscreen()
              .catch(err => {
                console.error('Failed to enter fullscreen mode:', err);
              });
        }
      }
    }
  }

}

</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0 8px 0 8px;
  border: 1px solid #ddd;

}


td:first-child {
  text-align: left;
}
</style>
<template>
  <q-card bordered class="shadow-0 bg-white q-mb-sm">
    <q-card-section ho class="q-pa-xs q-ma-none">
     {{label}}
    </q-card-section>
    <q-separator></q-separator>
    <q-card-section v-if="!loading" ho class="q-pa-xs">
      <q-card v-show="selectedItems.length>0" bordered class="shadow-0 bg-white q-pa-xs q-mb-sm">
        <q-card-section class="q-pa-none">
          <div class="row q-col-gutter-x-sm">
            <div class="col-auto" v-for="item in selectedItems" :key="item.id">
              <q-badge class="wrap" color="primary" :label="item.name">

                <q-icon
                    style="cursor:pointer;"
                    name="cancel"
                    size="14px"
                    class="q-ml-xs"
                    @click="removeItem(item)"
                />
              </q-badge>
            </div>
          </div>
        </q-card-section>
      </q-card>


      <div class="row q-col-gutter-x-xs q-col-gutter-y-sm q-mb-sm">
        <div v-for="(group,index) in grouped_options" :key="index" class="col-12 col-md-3 ">
          <div class="rounded-borders full-height" style=" border: 1px solid #dedcdc;">
            <div class="q-pa-sm">
              {{ group.name }}
            </div>
            <q-separator/>
            <div class="q-pa-xs">
              <div v-for="item in group.items" :key="item">
                <q-checkbox @click="singleSelection($event,item)" :val="item" v-model="selectedItems" color="primary"
                            dense>{{ item.code }}
                </q-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-card-section>
    <q-card-section v-if="loading" style="height: 100px">
      <q-inner-loading
          :showing="loading"
          size="md"
          color="grey-7"
      />
    </q-card-section>

  </q-card>
</template>

<script>


import {ref, computed, watch, onMounted} from "vue";

export default {

  name: 'FormSelect',

  emits: ['update:modelValue'],

  props: {
    options: {
      type: [Array, undefined, null],
      required: true
    },

    label: {
      type: String,
      required: false,
      default:'Forms'
    },

    loading: {
      type: Boolean,
      required: false,
      default:false
    },

    modelValue: {
      required: false,
      type: [Array, null, undefined]
    },

  },


  // eslint-disable-next-line
  setup(props, {emit}) {

    const grouped_options = computed(() => {
      const groupedData = props.options.reduce((acc, item) => {
        const groupName = item.group.name;
        const existingGroup = acc.find(g => g.name === groupName);
        if (existingGroup) {
          existingGroup.items.push(item);
        } else {
          acc.push({name: groupName, items: [item]});
        }
        return acc;
      }, []);

      return groupedData;
    })


    const selectedItems = ref(props.modelValue);

    // Watch for changes in the prop value and update the component's internal value accordingly
    watch(() => props.modelValue, (newValue) => {
      selectedItems.value = props.options.filter(option =>
          newValue.some(value => option.group.name === value.group.name && value.id === option.id)
      );
    });


    // Watch for changes in the prop value and update the component's internal value accordingly
    watch(() => props.options, (options) => {
      selectedItems.value = options.filter(option =>
          props.modelValue.some(value => option.group.name === value.group.name && value.id === option.id)
      );
    });

    onMounted(() => {
      selectedItems.value = props.options.filter(option =>
          props.modelValue.some(value => option.group.name === value.group.name && value.id === option.id)
      );
    })


    return {
      grouped_options,
      selectedItems,
      // eslint-disable-next-line no-unused-vars
      singleSelection(event, itemClicked) {
        selectedItems.value = selectedItems.value.filter((obj) => !(obj.group.name === itemClicked.group.name && obj.id != itemClicked.id));
        emit('update:modelValue', selectedItems.value)
      },
      removeItem(item) {
        const indexToRemove = selectedItems.value.findIndex((obj) => obj.id === item.id);

        if (indexToRemove !== -1) {
          selectedItems.value.splice(indexToRemove, 1);
        }
      }
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <q-card ref="parentElement"  class="rounded-borders bg-white shadow-0 q-mb-sm">
    <q-card-section class="q-py-xs  q-pr-xs">
      <div class="row items-center no-wrap">
        <div class="col full-height">
          <q-item-label class="text-bold" style="font-size: 1.0em">{{ schema.title }}</q-item-label>
        </div>
        <div class="col-auto full-height">
          <div class="q-gutter-xs">
            <q-btn v-show="isFullScreen" @click="toggle" dense color="grey-7" flat padding="0 5px 0 5px" icon="fullscreen_exit" :disable="loading"></q-btn>
            <q-btn v-show="!isFullScreen" dense flat padding="0 5px 0 5px" icon="more_vert" :disable="loading">
              <q-menu square  transition-show="jump-down"
                      transition-hide="jump-up" auto-close>
                <q-list dense style="min-width: 100px">
                  <q-item @click="toggle" dense clickable>

                    <q-item-section>
                      <q-item-label lines="1" header class="q-ma-none q-pa-none text-black"><div><q-icon size="sm" :name="`${isFullScreen?'fullscreen_exit':'fullscreen'}`" />{{`${!isFullScreen?'View Fullscreen':'Exit Fullscreen'}`}}</div></q-item-label>
                    </q-item-section>
                  </q-item>

                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
      </div>
    </q-card-section>
    <q-separator></q-separator>
    <q-card-section v-if="error" class="full-height">
      <div class="q-gutter-md row justify-center items-center flex full-height">
        <div class="q-gutter-sm text-center">
          <q-icon  name="warning" size="md" color="red" />
          <p class="justify-center text-center">
            {{ error }}
          </p>
        </div>
      </div>
    </q-card-section>
    <q-card-section v-else class="full-height">
      <div v-if="loading" class="q-gutter-md row justify-center items-center flex full-height">
        <div>
          <q-spinner
              color="primary"
              size="3em"
              :thickness="5"
          ></q-spinner>
        </div>
      </div>

      <div v-else class="row q-col-gutter-x-sm q-col-gutter-y-sm">
        <div class="col-12 col-md-3" v-for="datum in data" :key="datum">
          <SummaryBox :loading="true" :label="datum[schema.labelKey]" :value="datum[schema.valueKey]" ></SummaryBox>
        </div>
      </div>

    </q-card-section>
  </q-card>
</template>

<script lang="js">

import { onMounted, ref, watch} from 'vue'
import axios from "axios";
import config from "@/config";
import SummaryBox from "@/components/SummaryBox.vue";

export default {

  name: 'SummaryCard',
  components: {
    SummaryBox
  },
  props: {
    schema: {
      type: Object,
      require: true
    },
    url: String,
    headers: Array,
    withCredentials: {
      type: Boolean,
      default: false,
    }
  },

  // eslint-disable-next-line
  setup(props,{emit}) {


    // Detect changes in fullscreen mode
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        isFullScreen.value=true;
      } else {
        isFullScreen.value=false;
      }
    });





    const fetchData = () => {
      

      datasets.value = props.schema.datasets;

      //Create dataset query request without including query

      let datasetRequests=[]

      for (let [sequenceNumber, dataset] of datasets.value.entries()) {

        let datasetRequest={};

        datasetRequest.sequenceNumber=sequenceNumber;

        datasetRequest.indicator={
          code:dataset.indicator.code
        };

             datasetRequest.filter=dataset.filter?dataset.filter:[]

        datasetRequests.push(datasetRequest);

      }
      //Set to true if you want to test locally with sample data
      const testLocal = false;

      if (!testLocal) {

        loading.value = true
      error.value=null;

      axios.create({
        baseURL: config.baseUrl,
        timeout: 0,
        //Comment this when want to run as SPA
        withCredentials: true,
      })
          .post(`${props.url}`, datasetRequests,{
            headers: {}
          })

          // eslint-disable-next-line no-unused-vars
          .then(response => {

            let res = response.data;


            if(res.length>0){
              data.value=res[0].data;
            }


          })
          .catch(err => {


            if (err.response) {

              error.value=err.response.data.message?err.response.data.message:'Error occurred'


            } else if (err.request) {

              error.value='Failed to connect to the remote server'

            } else {
              // Anything else
              error.value='Failed to load data';
            }

          })
          .finally(() => loading.value = false)}
    }

    const data = ref([])

    const loading=ref(false)

    const datasets = ref(null);

    const isFullScreen = ref(false)

    const parentElement=ref(null)

    const error = ref(null)

    onMounted(() => {
      fetchData();
    })




    // eslint-disable-next-line no-unused-vars
    watch(() => props.url, (newValue, oldValue) => {
      fetchData()
    });

    return {
      data,
      loading,
      error,
      datasets,
      parentElement,
      isFullScreen,
      toggle:() =>{
        // const element = document.documentElement; // Get the root DOM element (usually the whole page)
        const element = parentElement.value.$el; // Get the root DOM element (usually the whole page)

        if (document.fullscreenElement) {
          // If already in fullscreen, exit fullscreen
          document.exitFullscreen();
        } else {
          // If not in fullscreen, request fullscreen
          element.requestFullscreen()
              .catch(err => {
                console.error('Failed to enter fullscreen mode:', err);
              });
        }
      }
    }
  }

}

</script>
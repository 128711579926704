<template>

  <q-card @click="onClick" class="shadow-0" :class="[ selected?'bg-'+activeColor:'bg-'+color,selected?'text-white':'text-black']"  bordered style="display: inline-block;min-width: 100px;cursor: pointer">
    <q-card-section class="q-pa-xs q-ma-none" style="font-size: 1.0em">
        <slot></slot>
    </q-card-section>
  </q-card>

</template>

<script>

import {computed } from 'vue'

export default {

  name: 'JasSelectableBox',

  emits: ['update:modelValue'],

  props: {
    activeColor: {
      required:false,
      type: String,
      default:"blue"
    },
    color: {
      required:false,
      type: String,
      default:"white"
    },
    modelValue: {
      required:true,
      type: [Number,String,null,undefined]
    },
    val: {
      required:true,
      type: [Number,String,null,undefined]
    },
  },


  // eslint-disable-next-line
  setup(props,{emit}) {

    // eslint-disable-next-line no-unused-vars
    const onClick = () => {
      if(!selected.value){
        emit('update:modelValue', props.val);
      }
    };

    const selected = computed(() => {
      // eslint-disable-next-line no-unused-vars
      if(props.modelValue===props.val){
        return true;
      }

      return false
    });


    return {
      selected,
      onClick
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selected {
  background-color: #0C426F;
  color: white;
}


.not-selected {
  background-color: #f4fbff;
  color: black;
}



.jas-tile{
  min-width: 100px;
  min-height: 50px ;
  display: inline-block;
}
</style>

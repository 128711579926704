<template>

  <q-select outlined option-label="name" hide-bottom-space input-class="q-ma-none q-pa-none " dense="dense"
            style="min-width: 50px"  @filter="filterFn" input-debounce="0" :options-dense="true"
            ref="countrySelect" class="ellipsis q-ma-none q-ma-none full-width" v-model="selectedCountry"
            :options="countries" :rules="[validate]">

    <template v-slot:option="props">
      <q-item clickable @click="onCountryChange(props.opt)" class="" style="cursor: pointer" dense>
        <q-tooltip>
          {{ props.opt.name }}
        </q-tooltip>
        <q-item-section top avatar>
          <q-avatar rounded>
            <q-icon v-if="props.selected" size="md" name="radio_button_checked"></q-icon>
            <q-icon v-else size="md" name="radio_button_unchecked"></q-icon>
          </q-avatar>
        </q-item-section>

        <q-item-section class="ellipsis q-pa-none q-ma-none" style="width: 80px">
          <q-item-label class=""> {{ props.opt.name }}</q-item-label>

        </q-item-section>

      </q-item>
    </template>
  </q-select>

</template>

<script>

import {ref, onMounted} from 'vue'
import countryOptions from "@/assets/countries";

export default {

  name: 'CountrySelect',

  components: {},

  emits: ['update:modelValue'],

  props: {

    dense: {
      required: false,
      default: false,
      type: Boolean
    },

    modelValue: {
      required: false,
      type: [String, null, undefined]
    },

  },


  // eslint-disable-next-line
  setup(props, {emit}) {


    const onCountryChange = (option) => {

      if (option) {
        emit('update:modelValue', option.name)
        selectedCountry.value=option;
      }
      countrySelect.value.toggleOption(option)
    }

    const countries = ref(countryOptions.map(value => value.name))

    const selectedCountry = ref(null);

    const countrySelect = ref(null)

    onMounted(async () => {


    })

    return {
      countrySelect,
      onCountryChange,
      selectedCountry,
      countries,

      validate(val){
        console.log(val)
           return (val) || 'Please select country'
      },

      filterFn(val, update) {
        if (!val || val.name === '') {
          update(() => {
            countries.value = countryOptions

            // here you have access to "ref" which
            // is the Vue reference of the QSelect
          })
          return
        }

        update(() => {
          const needle = val.toLowerCase()
          countries.value = countryOptions.filter(v => v.name.toLowerCase().indexOf(needle) > -1)
        })
      },
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
:deep(.q-field__before), :deep(.q-field__prepend) {
  padding-right: 0px !important;
}
</style>

<template>
  <div v-if="newForm">
    <div v-for="question in newForm.questions" :key="question.name">
      <template v-if="question.type !='repeated'">
        <q-item v-if="isRelevant(newForm,question) && question.active" dense
                class="item q-mb-sm bg-white q-px-sm shadow-1 rounded-borders">
          <q-item-section class="q-pr-none">
            <q-item-section class="q-mt-sm">
              <q-item-label class="text-grey-7 text-bold"><span v-if="question.required" class="text-negative">*</span>{{ question.label }}
              </q-item-label>
              <q-item-label v-if="question.hint" class="text-primary text-bold" caption>{{ question.hint }}
              </q-item-label>
            </q-item-section>
            <div v-if="question.type==='text'">
              <q-input input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)"
                       :readonly="question.readOnly"
                       dense type="text"
                       v-model="question.value">
              </q-input>
            </div>
            <div v-else-if="question.type==='date'">
              <q-input input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)"
                       :readonly="question.readOnly"
                       dense
                       v-model="question.value">
                <q-popup-proxy transition-show="flip-up" transition-hide="scale">
                  <q-banner class="q-ma-none no-shadow q-pa-none">
                    <q-date no-unset v-model="question.value" mask="YYYY-MM-DD" class="no-border shadow-0" square
                            minimal>
                      <div class="row items-center justify-end q-gutter-sm">
                        <q-btn label="Done" color="primary" flat v-close-popup/>
                      </div>
                    </q-date>
                  </q-banner>
                </q-popup-proxy>
                <template v-slot:append>
                  <q-icon name="event">
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div v-else-if="question.type==='time'">
              <q-input input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)"
                       :readonly="question.readOnly"
                       dense
                       v-model="question.value">
                <q-popup-proxy transition-show="flip-up" transition-hide="scale">
                  <q-banner class="q-ma-none no-shadow q-pa-none">
                    <q-time no-unset format24h v-model="question.value" mask="HH:mm" class="no-border shadow-0" square
                            minimal>
                      <div class="row items-center justify-end q-gutter-sm">
                        <q-btn label="Done" color="primary" flat v-close-popup/>
                      </div>
                    </q-time>
                  </q-banner>
                </q-popup-proxy>
                <template v-slot:append>
                  <q-icon name="event">
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div v-else-if="question.type==='number'">
              <q-input input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)"
                       mask="/^-?\d*$/"
                       :readonly="question.readOnly" dense type="number" v-model="question.value"></q-input>
            </div>
            <div v-else-if="question.type==='decimal'">
              <q-input input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)"
                       mask="/^-?\d*$/"
                       :readonly="question.readOnly" stack-label :label="question.label"
                       :hint="question.hint" type="decimal" v-model="question.value"></q-input>
            </div>
            <div v-if="question.type==='calculate'">
<!--              {{calculate(newForm)}}-->
              <q-input input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)"
                       :readonly="question.readOnly"
                       dense type="text"
                       :model-value="calculate(newForm,question)">
              </q-input>
            </div>
            <div v-else-if="question.type==='drop_down'">
              <q-select input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)"
                        :readonly="question.readOnly"
                        :options="question.filteredOptions"
                        @filter="(val, update, abort) => { dropDownFilterFn(val, update,newForm, question)}"
                        @update:model-value="(val) => { onDropDownChanged(val,newForm,question)}"
                        dense
                        v-model="question.value"></q-select>
            </div>
            <div v-else-if="question.type==='multiple_choice'">
              <q-select input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)"
                        :readonly="question.readOnly"
                        multiple :options="question.filteredOptions"
                        @filter="(val, update, abort) => { dropDownFilterFn(val, update,newForm, question)}"
                        @update:model-value="(val) => { onDropDownChanged(val,newForm,question)}"
                        dense
                        v-model="question.value"></q-select>
            </div>
            <div v-else-if="question.type==='position'">
              <q-input disable input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)"
                       :readonly="question.readOnly"
                       dense
                       v-model="question.value">

                <template v-slot:after>
                  <q-btn @click="setLocation(question)" color="green-7" icon="my_location"></q-btn>
                </template>
              </q-input>
            </div>
            <div v-else-if="question.type==='auto_complete'">
              <q-select input-class="text-grey-8 text-bold" reactive-rules :rules="validate(newForm,question)" use-input
                        input-debounce="0"
                        :readonly="question.readOnly" :options="question.options" dense
                        @filter="(val, update, abort) => { autoCompleteFilterFn(val, update, newForm,question)}"
                        @update:model-value="(val) => { onDropDownChanged(val,newForm,question)}"
                        v-model="question.value"></q-select>
              <!--                    <q-input :readonly="question.readOnly" stack-label :label="question.label" :hint="question.hint"   v-model="question.value"></q-input>-->
            </div>

          </q-item-section>
        </q-item>
      </template>
      <div v-else-if="question.type === 'repeated'">
        <q-card v-if="question.subForm" class="q-my-sm">
          <q-card-section class="q-py-sm">
              <span class="text-bold">
                {{ question.subForm.name }}
              </span>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section>
            <q-expansion-item
                v-for="subForm in question.value" :key="subForm"
                :label="subForm.localId"
                v-model="subForm.collapsed"
                class="bg-grey-3 q-mb-sm rounded-borders"
                style="border: 1px light #cbcbcb"
            >
              <q-card class="shadow-0" bordered>
                <q-card-section>
                  <form-editor :is-child="true" :options="options" :parent-id="newForm.localId" :model-value="subForm"/>
                </q-card-section>
                <q-separator></q-separator>
                <q-card-section class="q-py-xs bg-grey-2">
                  <div class="column items-end">
                    <div class="col">
                      <q-btn class="rounded-borders text-white bg-red-5 items-end"
                             @click="onDeleteForm(subForm,question)" flat square dense padding="3px 15px 3px 15px"
                             color="white" no-caps label="Delete"></q-btn>
                    </div>

                  </div>
                </q-card-section>
              </q-card>
            </q-expansion-item>
            <q-btn icon="add" no-caps class="rounded-borders" @click="addNew(question)" square dense
                   padding="5px 15px 5px 5px" color="primary" :label="'Add '+question.subForm.code"></q-btn>
          </q-card-section>

        </q-card>

      </div>

    </div>
  </div>
</template>

<script>

import {onMounted, ref, watch} from "vue";

import {useQuasar} from "quasar";

function insertValues(qtn, expression) {
  if (expression) {
    let val = "";

    if ((qtn.type === "text")) {
      val = qtn.value;
    } else if (qtn.type === "number") {
      val = parseInt(qtn.value)
    } else if (qtn.type === "decimal") {
      val = parseFloat(qtn.value)
    } else if (qtn.type === "drop_down" || qtn.type === "auto_complete") {

      if (!qtn.value || qtn.value.length < 0 || qtn.value === '') {
        val = ""
      } else {

        val = qtn.value.value
      }

    } else if (qtn.type === "multiple_choice") {
      if (!qtn.value || qtn.value === '') {
        val = ""
      } else {
        val = qtn.value.map(obj => obj.value).join(' ');
      }
    }

    return expression.replace(`@{${qtn.name}}`, `'${val}'`);
  } else {
    return ""
  }
}


// eslint-disable-next-line no-unused-vars
function contains(string, value) {
  return string.includes(value)
}

// eslint-disable-next-line no-unused-vars
function length(string) {
  return string.length;
}

// eslint-disable-next-line no-unused-vars
function toUpperCase(string) {
  return string.toUpperCase();
}

// eslint-disable-next-line no-unused-vars
function toLowerCase(string) {
  return string.toLowerCase();
}




export default {

  name: 'FormEditor',

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    parentId: {
      type: String,
      required: false
    },
    isChild: {
      type: Boolean,
      default: false
    }
  },


  // eslint-disable-next-line
  setup(props, {emit}) {

    const newForm = ref(null);

    const $q = useQuasar()

    // eslint-disable-next-line no-unused-vars
    const addNew = (question) => {
      question.value.push(JSON.parse(JSON.stringify(question.subForm)))

    }

    const initializeValue = (form, question) => {
      if (question.value == null || question.value == undefined) {
        if (question.initialValue && question.initialValue != "") {
          let replacedString = question.initialValue.replace(/@form/g, `'${form.code}'`);
          // eslint-disable-next-line no-debugger
          question.value = eval(replacedString)
        }
      }
    }

    const isRelevant = (form, question) => {
      let relevant = question.relevant
      // let relevant="@{vi}===@{fr}"

      if (!relevant || relevant.length < 1 || relevant === '') {
        return true;
      }


      for (let qtn of form.questions) {

        relevant = insertValues(qtn, relevant);

      }


      if (eval(relevant)) {
        return true;
      }

      question.value = null;

      return false; //
    }


    const validate = (form, question) => {
      return [
        (val) => {

          if (question.required) {

            if (!val || val === '' || val == undefined || val === null) {
              return "You must answer this question"
            }

            if (question.type === "text") {
              if (val.length > 0) {
                return true
              } else {
                return "You must answer this question"
              }
            }
          }

          return true;

        },

        // eslint-disable-next-line no-unused-vars
        (value) => {


          let constraint = question.contraint
          // let constraint="@{vi}===@{fr}"

          if (!constraint || constraint.length < 1 || constraint === '') {
            return true;
          }

          for (let qtn of form.questions) {
            constraint = insertValues(qtn, constraint);
          }


          if (eval(constraint)) {
            return true;
          }

          return `${question.constraintMsg}`; // Validation passed
        }
      ];
    }



    // eslint-disable-next-line no-unused-vars
    const getValues = (formCode, qtnName, val) => {
      return props.options.getValues(formCode, qtnName, val);
    }

    // eslint-disable-next-line no-unused-vars
    const getId = (form) => {
      if (form && form.localId) {
        return form.localId
      } else {
        return props.options.getId(form.code)
      }
    }


    const addNumbers = (...values) => {
      let result = 0;

      for (let i = 0; i < values.length; i++) {
        const currentNumber = parseFloat(values[i]);
        if (!isNaN(currentNumber)) {
          result += currentNumber;
        }
      }

      return result;

    }

    const calculate=(form,question)=> {


      let expression = question.calculation
      // let relevant="@{vi}===@{fr}"

      if (!expression || expression.length < 1 || expression === '') {
        return "";
      }


      for (let qtn of form.questions) {

        expression = insertValues(qtn, expression);

      }


      //
      // for (let qtn of form.questions) {
      //
      //   if(qtn.name==="num1"){
      //     question.value=qtn.value;
      //     return qtn.value;
      //   }
      //
      // }


      let value=eval(expression)



      question.value =value;

      return value;
    }



    const roundOffNumber=(number,decimals)=> {
      // Use toFixed to round the number to two decimal places
      return number.toFixed(decimals);
    }


    const calculateIRMortalityRate=(controlMortality,...repsMRS)=> {

      const treatmentMortality= repsMRS.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue;
      }, 0)/repsMRS.length;


      if(controlMortality<5){
        return treatmentMortality;
      }

      if(controlMortality>=5 && controlMortality<=20){

        return ((treatmentMortality-controlMortality)/(100-controlMortality))*100;

      }

      return -1;

    }

    onMounted(() => {
      newForm.value = props.modelValue
      if (newForm.value) {
        modifyQuestions(newForm.value)
      }
    })


    // eslint-disable-next-line no-unused-vars
    watch(() => newForm.value, (newValue, oldValue) => {
      emit('update:modelValue', newValue)
    }, {deep: true});


    // eslint-disable-next-line no-unused-vars
    function modifyQuestions(form) {

      form.localId = getId(form)
      for (const question of form.questions) {
        if (question.name === "fr") {
          question.value = form.localId
          pid.value = form.localId;

        } else if (question.name === "sfr") {
          question.value = {
            label: props.parentId,
            value: props.parentId,
            group: props.parentId,
          }
          question.readOnly = props.isChild
        } else {
          initializeValue(form, question)
        }

        if (question.type === "multiple_choice" && (question.value == null || question.value == undefined || question.value == "")) {
          question.value = [];
        }

        if (question.type === "repeated") {
          if (question.subForm && question.value == null) {
            let tempForm = JSON.parse(JSON.stringify(question.subForm));
            question.value = [...[], tempForm]
            modifyQuestions(tempForm)
          }
        }
      }

    }

    const createQuestions = (form) => {
      return form.answers = [form.answers ? [] : form.answers, ...form.questions];
    }


    const pid = ref(null);


    const autoCompleteFilterFn = (val, update, form, question) => {

      // Custom filtering logic for each q-select
      // You can access the specific index here
      console.log(`Filtering for q-select ${question.name}`);


      let optionSource = question.optionSource
      // let constraint="@{vi}===@{fr}"

      if (!optionSource || optionSource.length < 1 || optionSource === '') {
        update()
        return
      }

      optionSource = optionSource.replace(`@{${question.name}}`, `'${val}'`);

      eval(optionSource).then(response => {
        update(() => {
          question.options = response
        })
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        $q.notify({
          message: 'Oops, failed to load options',
          type: 'negative',
          position: 'top',
          color: "dark"
        });
      })
    }


    const dropDownFilterFn = (val, update, form, question) => {

      let filter = question.filter

      if (!filter || filter.length < 1 || filter === '') {
        update(() => {
          question.filteredOptions = question.options
        })
      }


      for (let qtn of form.questions) {
        filter = insertValues(qtn, filter);

      }

      filter = filter.substring(1, filter.length - 1)

      update(() => {
        question.filteredOptions = question.options.filter(v => v.group.toLowerCase().indexOf(filter.toLowerCase()) > -1)
      })


    }


    const onDropDownChanged = (value, form, question) => {

      for (let qtn of form.questions) {

        if (qtn.filter && qtn.filter.includes(`@{${question.name}}`)) {
          qtn.value = null;
        }
      }
    }

    const setLocation = (question) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              let latitude = position.coords.latitude;
              let longitude = position.coords.longitude;
              let accuracy = position.coords.accuracy;

              question.value = `${latitude} ${longitude} ${accuracy}`
            },
            (error) => {
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  $q.notify({
                    message: 'Location information is unavailable.',
                    type: 'negative',
                    position: 'top',
                    color: "negative"
                  });
                  break;
                case error.POSITION_UNAVAILABLE:
                  $q.notify({
                    message: 'Geolocation is not supported by this browser.',
                    type: 'negative',
                    position: 'top',
                    color: "negative"
                  });
                  break;
                case error.TIMEOUT:
                  $q.notify({
                    message: 'The request to get user location timed out.',
                    type: 'negative',
                    position: 'top',
                    color: "negative"
                  });
                  break;
                case error.UNKNOWN_ERROR:
                  $q.notify({
                    message: 'An unknown error occurred.',
                    type: 'negative',
                    position: 'top',
                    color: "negative"
                  });
                  break;
              }
            },
            {
              enableHighAccuracy: true,
              maximumAge: 0,
              timeout: 5000
            }
        );
      } else {
        $q.notify({
          message: 'Geolocation is not supported by this browser.',
          type: 'negative',
          position: 'top',
          color: "negative"
        });
      }
    };


    return {
      setLocation,
      autoCompleteFilterFn,
      dropDownFilterFn,
      onDropDownChanged,
      pid,
      newForm,
      initializeValue,
      isRelevant,
      calculate,
      roundOffNumber,
      calculateIRMortalityRate,
      validate,
      addNew,
      createQuestions,
      getId,
      addNumbers,
      getValues,
      // eslint-disable-next-line no-unused-vars
      onDeleteForm(form, question) {
        $q.dialog({
          title: 'Confirm',
          message: 'Would you like to delete this form?',
          cancel: true,
          persistent: true
        }).onOk(() => {

          // Using findIndex to get the index of the item with the matching ID
          const indexToDelete = question.value.findIndex(item => item.id === form.id);

          if (indexToDelete !== -1) {
            // Using splice to delete the item at the specified index
            question.value.splice(indexToDelete, 1);
          }
        })

      },
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

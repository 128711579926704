<template>
  <q-list bordered class="rounded-borders q-pa-xs bg-white">
    <q-expansion-item

        expand-icon="expand_circle_down"

        :label="question.label"
        :caption="question.name"
        header-class="bg-white"
        expand-separator
    >

      <template v-slot:header>
        <q-item class="full-width">
          <q-item-section class="q-mr-none" avatar>
            <slot name="handle">
              <q-icon id="handle" name="drag_indicator" size="xs" color="grey-10" text-color="white"></q-icon>
            </slot>
          </q-item-section>
          <q-item-section side avatar>
            <q-checkbox :disable="!question.canDeactivate"
                        v-model="question.active" label="" dense>
            </q-checkbox>

          </q-item-section>

          <q-item-section>
            <q-item-label lines="1">{{ question.label }} <b>({{ question.name }})</b></q-item-label>
            <q-item-label caption>
              {{ question.hint }}
            </q-item-label>
            <q-item-label lines="1" class=" ">
              Type: <span class="text-primary cursor-pointer"> {{ question.type }}</span>
            </q-item-label>
          </q-item-section>


          <q-item-section side>
            <div class="text-grey-8 q-gutter-xs">
              <q-btn color="negative" :disable="!question.canDeactivate" @click="onQuestionDelete" class="gt-xs" flat
                     dense round icon="delete_outline"/>
            </div>
          </q-item-section>
        </q-item>
      </template>

      <q-card class="bg-grey-1 q-mt-sm q-mx-none" style="">
        <q-card-section class="q-pa-none">
          <table class="table full-width table-bordered q-mb-sm">

            <tbody class="align-center">

            <template v-if="question.type==='repeated'">
              <tr>
                <td colspan="2" style="width: 35%" class="">
                  <div class="row">
                    <div class="items-center">

                    </div>
                    <div>
                      <q-select :disable="!question.canDeactivate" borderless v-model="question.type"
                                class="q-pa-none q-ma-none" dense options-dense :options="inputTypes">
                        <template v-slot:before>
                          <span class="text-bold text-h6 text-black" style="font-size: 0.7em">Type:</span>
                        </template>
                      </q-select>
                    </div>
                  </div>


                </td>

                <td colspan="3" style="width: 50%" class=""><span
                    class="text-bold ">Form Code:</span>
                  {{ question.name }}
                  <q-popup-edit
                      buttons :disable="!question.canDeactivate" v-model="question.name"
                      :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>

              </tr>
            </template>
            <template v-else>
              <tr>
                <td style="width: 7%" class="text-bold">Label:</td>
                <td colspan="4">
                  {{ question.label }}
                  <q-popup-edit
                      buttons v-model="question.label" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>

              </tr>

              <tr>
                <td style="width: 7%" class="text-bold">Hint:</td>
                <td colspan="4">
                  {{ question.hint }}
                  <q-popup-edit
                      buttons v-model="question.hint" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>
              </tr>

              <tr>
                <td colspan="2" style="width: 35%" class="">
                  <div class="row">
                    <div class="items-center">

                    </div>
                    <div>
                      <q-select :disable="!question.canDeactivate" borderless v-model="question.type"
                                class="q-pa-none q-ma-none" dense options-dense :options="inputTypes">
                        <template v-slot:before>
                          <span class="text-bold text-h6 text-black" style="font-size: 0.7em">Type:</span>
                        </template>
                      </q-select>
                    </div>
                  </div>


                </td>

                <td colspan="3" style="width: 50%" class=""><span
                    class="text-bold ">Variable Name:</span>
                  {{ question.name }}
                  <q-popup-edit
                      buttons :disable="!question.canDeactivate" v-model="question.name"
                      :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>

              </tr>

              <tr>
                <td colspan="2" style="width: 50%" class=""><span
                    class="text-bold ">Required: </span>
                  <q-checkbox v-model="question.required" dense></q-checkbox>
                </td>

                <td :colspan="question.type==='text'?2:3" style="width: 50%" class=""><span
                    class="text-bold ">Read only: </span>
                  <q-checkbox v-model="question.readOnly" dense></q-checkbox>
                </td>

                <td v-if="question.type==='text'" colspan="1" style="width: 50%" class=""><span
                    class="text-bold ">Text Area: </span>
                  <q-checkbox v-model="question.textArea" dense></q-checkbox>
                </td>


              </tr>


              <tr v-if="question.type==='calculate'">

                <td v-if="question.type==='calculate'" colspan="7" style="width: 50%" class="">
                  <span
                      class="text-bold ">Calculation: </span>
                  {{ question.calculation }}
                  <q-popup-edit
                      buttons v-model="question.calculation" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>

              </tr>

              <tr>
                <td colspan="2" style="width: 50%" class=""><span
                    class="text-bold ">Editable: </span>
                  <q-checkbox v-model="question.editable" dense></q-checkbox>
                </td>

                <td colspan="3" style="width: 50%" class=""><span
                    class="text-bold ">Process Location: </span>
                  <q-checkbox :disable="question.type!='auto_complete'" v-model="question.processLocation"
                              dense></q-checkbox>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="width: 50%" class=""><span
                    class="text-bold ">Printable: </span>
                  <q-checkbox v-model="question.printable" dense></q-checkbox>
                </td>

                <td colspan="3" style="width: 50%" class=""><span
                    class="text-bold ">Scannable: </span>
                  <q-checkbox v-model="question.scannable" dense></q-checkbox>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="width: 50%" class="">
                                    <span
                                        class="text-bold ">Constraint: </span>
                  {{ question.contraint }}
                  <q-popup-edit
                      buttons v-model="question.contraint" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>

                <td colspan="3" style="width: 50%" class="">
                  <span class="text-bold ">Constraint Message: </span>
                  {{ question.constraintMsg }}
                  <q-popup-edit
                      buttons v-model="question.constraintMsg" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>

              </tr>
              <tr>
                <td colspan="2" style="width: 50%" class="">
                                    <span
                                        class="text-bold ">Relevant: </span>
                  {{ question.relevant }}
                  <q-popup-edit
                      buttons v-model="question.relevant" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>

                <td colspan="3" style="width: 50%" class="">
                  <span class="text-bold ">Initial value: </span>
                  {{ question.initialValue }}
                  <q-popup-edit
                      buttons v-model="question.initialValue" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>

              </tr>
              <tr v-if="question.type=='auto_complete'">
                <td colspan="2" style="width: 50%" class="">
                                    <span
                                        class="text-bold ">Option Source: </span>
                  {{ question.optionSource }}
                  <q-popup-edit
                      buttons v-model="question.optionSource" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>

                <td colspan="3" style="width: 50%" class=""><span
                    class="text-bold ">Process Location: </span>
                  <q-checkbox color="teal" v-model="question.processLocation" dense></q-checkbox>
                </td>

              </tr>
              <template
                  v-if="question.type=='drop_down' || question.type=='multiple_choice' || question.type=='single_choice'">
                <tr>
                  <td colspan="5" style="width: 50%" class="">
                                    <span
                                        class="text-bold ">Option Filter: </span>
                    {{ question.filter }}
                    <q-popup-edit
                        buttons v-model="question.filter" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>


                </tr>
                <tr>

                  <td colspan="5" class="text-bold">
                    <div class="row q-ma-none q-pa-none">
                      <div class="text-bold">Options <span class="text-primary text-italic" style="font-size: 0.8em"> (You can import options from csv file with three columns named label, value and group) </span>
                      </div>
                      <q-space></q-space>
                      <q-btn dense @click="addOption" size="sm" no-caps
                             flat
                             icon="add"></q-btn>
                      <q-btn dense size="sm" no-caps flat icon="upload_file"
                             @click="uploadOptions(evt,question)"></q-btn>
                      <q-file max-file-size="102400000"
                              @rejected="onFileRejected"
                              accept=".csv,text/csv" ref="fileInput" v-model="optionsFile"
                              @update:model-value="handleFileChange" style="display: none;"/>

                    </div>
                  </td>
                </tr>


                <tr class="bg-grey-2 text-bold">
                  <td colspan="1" style="width: 2%">#</td>
                  <td colspan="1" style="width: 35%">Label</td>
                  <td colspan="1" style="width: 35%">Value</td>
                  <td colspan="1" style="width: 26%">Group</td>
                  <td colspan="1" style="width: 2%">
                    <q-btn dense size="sm" no-caps flat color="white" icon="clear"></q-btn>
                  </td>
                </tr>

                <tr v-for="(option,index) in question.options" :key="index" class="">
                  <td colspan="1" style="width: 2%">{{ index + 1 }}</td>
                  <td colspan="1">{{ option.label }}
                    <q-popup-edit
                        buttons v-model="option.label" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1">{{ option.value }}
                    <q-popup-edit
                        buttons v-model="option.value" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1">{{ option.group }}
                    <q-popup-edit
                        buttons v-model="option.group" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="width: 2%">
                    <q-btn @click="removeOption(evt,index) " dense size="sm"
                           no-caps
                           flat color="negative" icon="clear"></q-btn>
                  </td>
                </tr>

              </template>
            </template>


            </tbody>
          </table>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </q-list>


</template>

<script>
import {useQuasar} from "quasar";

import {ref, onMounted, watch} from "vue";

export default {

  name: 'QuestionEditor',

  emits: ['update:modelValue', 'delete'],

  props: {
    modelValue: {
      required: true,
      type: Object
    },
  },


  // eslint-disable-next-line
  setup(props, {emit}) {

    const $q = useQuasar()

    function csvToJson(csvText) {
      // Split the CSV text into lines
      const lines = csvText.split("\n");

      // Get the headers (first line)
      const headers = lines[0].split(",");

      const jsonData = [];

      // Iterate through the lines starting from the second line (data lines)
      for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        const data = {};

        for (let j = 0; j < headers.length; j++) {
          data[headers[j]] = currentLine[j];
        }

        jsonData.push(data);
      }

      return jsonData;
    }

    const question = ref({})

    const fileInput = ref(null)

    const optionsFile = ref(null)

    onMounted(() => {
      question.value = props.modelValue;
    })

    // eslint-disable-next-line no-unused-vars
    watch(() => question.value, (newValue, oldValue) => {
      emit('update:modelValue', newValue)
    }, {deep: true});

    return {
      question,
      fileInput,
      optionsFile,
      inputTypes: ref([
        'text',
        'date',
        'label',
        'decimal',
        'number',
        'position',
        'drop_down',
        'multiple_choice',
        'auto_complete',
        'calculate',
        'repeated'
      ]),
      onQuestionDelete(event) {
        $q.dialog({
          title: 'Confirm',
          message: 'Would you like to delete this question?',
          cancel: true,
          persistent: true
        }).onOk(() => {
          emit('delete')
        })

        event.stopPropagation();

      },
      addOption() {
        if (question.value.options) {
          question.value.options.push({});
        } else {
          question.value.options = [
            {}
          ];
        }

      },
      removeOption(evt, index) {
        question.value.options.splice(index, 1);
      },

      // eslint-disable-next-line no-unused-vars
      uploadOptions(evt, question) {


        optionsFile.value = null;

        // Create a new MouseEvent to simulate a click event
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        fileInput.value.$el.dispatchEvent(clickEvent);
      },

      // eslint-disable-next-line no-unused-vars
      onFileRejected(rejectedEntries) {

        $q.notify({
          type: 'negative',
          message: `File must be CSV and not exceed 10MB`
        })
      },

      handleFileChange(file) {
        if (file) {
          const reader = new FileReader();

          // Read file as string
          reader.readAsText(file);

          reader.onload = function (e) {
            const text = e.target.result;

            csvToJson(text).forEach(option => {
              if ((option.label || option.label != '') && (option.value || option.value != '')) {
                if (question.value.options) {
                  question.value.options.push(option);
                } else {
                  question.value.options = [
                    option
                  ];
                }
              }

            })


          };


        }
      },
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  border-collapse: collapse;
  border: 1px solid #69899F;
}

table td {
  border: 1px dotted #000000;
  padding: 5px;
}

table td:first-child {
  border-left: 0px solid #000000;
}

table th {
  border: 2px solid #69899F;
  padding: 5px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.item {
  border: 1px solid #575757;
  margin: 0.1rem;
}

.sortable-ghost {
  border: 1px dashed blue;
  font-size: 0;
  overflow: hidden;
}

#handle:hover {
  cursor: move;
}
</style>

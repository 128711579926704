<template>
  <q-card v-intersection.once="onIntersection"  ref="parentElement" class="rounded-borders bg-white shadow-0 q-mb-sm">
    <q-card-section class="q-py-xs  q-pr-xs">
      <div class="row items-center no-wrap">
        <div class="col full-height">
          <q-item-label class="text-bold" style="font-size: 1.0em">{{ schema.title }}</q-item-label>
        </div>
        <div class="col-auto full-height">
          <div class="q-gutter-xs">
            <q-btn v-show="isFullScreen" @click="toggle" dense color="grey-7" flat padding="0 5px 0 5px" icon="fullscreen_exit" :disable="loading"></q-btn>
            <q-btn v-show="!isFullScreen" dense flat padding="0 5px 0 5px" icon="more_vert" :disable="loading">
              <q-menu square transition-show="jump-down"
                       transition-hide="jump-up" auto-close>
                <q-list dense style="min-width: 100px">
                  <q-item @click="toggle" dense clickable>

                    <q-item-section>
                      <q-item-label lines="1" header class="q-ma-none q-pa-none text-black"><div><q-icon size="sm" :name="`${isFullScreen?'fullscreen_exit':'fullscreen'}`" />{{`${!isFullScreen?'View Fullscreen':'Exit Fullscreen'}`}}</div></q-item-label>
                     </q-item-section>
                  </q-item>

                </q-list>
              </q-menu>
            </q-btn>

          </div>
        </div>
      </div>
    </q-card-section>
    <q-separator></q-separator>
    <q-card-section v-if="error" class="full-height">
      <div :style="{'min-height':`${schema.height+'px'}`}" class="q-gutter-md row justify-center items-center flex full-height">
        <div class="q-gutter-sm text-center">
          <q-icon  name="warning" size="md" color="red" />
          <p class="justify-center text-center">
            {{ error }}
          </p>
        </div>
      </div>
    </q-card-section>
    <q-card-section v-else class="full-height q-mt-sm" style="padding: 1px 1px 1px 8px">
      <div :style="{'min-height':`${schema.height+'px'}`}" v-show="loading" class="q-gutter-md row justify-center items-center flex full-height">
          <q-spinner
              color="primary"
              size="3em"
              :thickness="5"
          ></q-spinner>
      </div>

      <div v-show="!loading" class="row q-col-gutter-x-sm q-col-gutter-y-sm full-height">

        <!--        <div :style="{'width':w,'height':'200px'}">-->
        <div :id="uniqueId" class="container" :style="{'width':'100%','height':`${isFullScreen?'100%':schema.height+'px'}`,'border':'1px solid lightgrey'}">

        </div>

      </div>

    </q-card-section>
  </q-card>


</template>

<script lang="js">

import { onMounted, ref, watch, onBeforeMount} from 'vue'
import axios from "axios";
import config from "@/config";
import "leaflet/dist/leaflet.css";
import L from "leaflet";


export default {

  name: 'CircleMap',
  components: {},
  props: {
    schema: {
      type: Object,
      require: true
    },
    url: String,
    headers: Array,
    withCredentials: {
      type: Boolean,
      default: false,
    }
  },

  // eslint-disable-next-line
  setup(props, {emit}) {



    // Detect changes in fullscreen mode
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        isFullScreen.value=true;
      } else {
        isFullScreen.value=false;
      }
    });




    const fetchData = () => {

      if (visible.value){
      if (map.value) {
        map.value.remove();
      }

      map.value = L.map(`${uniqueId.value}`, {
        center: [props.schema.centerLat, props.schema.centerLng],
        zoom: props.schema.zoom, zoomAnimation: false
      })
      // Disable zoom with mouse scroll after setting the initial view
      map.value.scrollWheelZoom.disable();

      datasets.value = props.schema.datasets;

      //Create dataset query request without including query

      let datasetRequests = []

      for (let [sequenceNumber, dataset] of datasets.value.entries()) {

        let datasetRequest = {};

        datasetRequest.sequenceNumber = sequenceNumber;

        datasetRequest.indicator = {
          code: dataset.indicator.code
        };

        datasetRequest.filter = dataset.filter ? dataset.filter : []

        datasetRequests.push(datasetRequest);

      }
      //Set to true if you want to test locally with sample data
      const testLocal = false;

      // Create the legend control
      const legendControl = L.control({position: 'topright'});


      if (!testLocal) {

        loading.value = true

        error.value = null;

        axios.create({
          baseURL: config.baseUrl,
          timeout: 0,
          //Comment this when want to run as SPA
          withCredentials: true,
        })
            .post(`${props.url}`, datasetRequests, {
              headers: {}
            })

            // eslint-disable-next-line no-unused-vars
            .then(response => {


              let res = response.data;

              // let res = [
              //   {
              //     "indicator": {
              //       "code": "AGCM"
              //     },
              //     "data": [
              //       {
              //         location: "-6.173 35.70",
              //         value: 23,
              //       },
              //       {
              //         location: "  -6.173 35.750",
              //         value: 1000,
              //       },
              //       {
              //         location: "-6.173 35.750",
              //         value: 21,
              //       }
              //     ]
              //   }
              // ]

              if (res.length > 0) {
                data.value = res[0].data;
              }


              L.tileLayer('https://cartodb-basemaps-b.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://carto.com">CartoDB</a> contributors'
              }).addTo(map.value);


              const options = props.schema.legend.options;


              // Loop through the circle data and create circles on the map
              data.value.forEach(function (dataPoint) {
                // Calculate the radius (assuming it's proportional to the value)
                //const radius = dataPoint.value > 100 ? dataPoint.value / 1000 : dataPoint.value;


                // Determine the circle color based on the value range
                let circleColor = 'white';
                let radius = 30;
                let textColor = 'black';
                let borderColor = 'white';


                for (const prop in options) {
                  let option = options[prop];
                  if (dataPoint[props.schema.valueKey] >= option.min && dataPoint[props.schema.valueKey] <= option.max) {
                    circleColor = option.color;
                    textColor = option.textColor;
                    borderColor = option.borderColor;
                    radius = option.radius;
                    break;
                  }
                }


                // Create a custom circle element
                const customCircle = document.createElement('div');
                customCircle.className = 'custom-circle';
                customCircle.style.cssText = `background-color:${circleColor}; width: ${radius}px;height: ${radius}px; line-height: ${radius}px;color: ${textColor};border: 1px solid ${borderColor};`;


                // Set the circle's text content (modified label)

                if (props.schema.showCircleLabel) {
                  if (dataPoint[props.schema.valueKey] >= 1000) {
                    customCircle.textContent = (dataPoint[props.schema.valueKey] / 1000).toFixed(1) + 'k';

                  } else {
                    customCircle.textContent = dataPoint[props.schema.valueKey];
                  }

                }
                const location = dataPoint[props.schema.locationKey].split(" ");

                // Create a Leaflet marker with the custom circle
                const marker = L.marker([location[0], location[1]], {
                  icon: L.divIcon({
                    className: 'custom-div-icon',
                    html: customCircle.outerHTML
                  })
                }).addTo(map.value);


                // Create a custom HTML content for the popup with JSON data
                let popupContent = '<div>';
                for (const key in dataPoint) {
                  popupContent += '<span style="font-weight: bold">' + key + ': </span><span>' + dataPoint[key] + '</span><br>';
                }
                popupContent += '</div>';

                // Create a popup for the marker with the custom HTML content
                const popup = L.popup().setContent(popupContent);
                marker.bindPopup(popup);

              });


              // Function to initialize the legend
              legendControl.onAdd = function () {
                const legendDiv = L.DomUtil.create('div', 'legend');
                legendDiv.innerHTML += `<div style=font-weight:bold;display:inline-block;text-align:center;  line-height: 20px; /* same as height! */"> ${props.schema.legend.title}</div><br>`

                // Create the legend content
                for (let i = 0; i < options.length; i++) {
                  //legendDiv.innerHTML += `<div style="display:inline-block;text-align:center;  line-height: 20px; /* same as height! */"><div class="" style="background-color: ${options[i].color};width:30px;height:15px;color:blue;display:inline-block;"></div><span style="line-height: 15px" class="legend-label bg-red"> ${options[i].label}</span></div><br>`
                  legendDiv.innerHTML += `    <div class="row text-dark">
                <div class="col" style="background-color: ${options[i].color};margin: 1px">
                  <div class="row  q-gutter-lg">
                     <div class="bg-white">

                    </div>
                    <div class="col bg-white q-pl-xs" style="">
                     ${options[i].label}
                    </div>
                  </div>
                </div>
              </div>`
                }


                // Prevent default action (zoom) on click
                L.DomEvent.on(legendDiv, 'click', function (event) {
                  L.DomEvent.preventDefault(event);
                });

                // Prevent default action (zoom) on click
                L.DomEvent.on(legendDiv, 'mousedown mouseup click dblclick', function (event) {
                  event.stopPropagation()
                });


                return legendDiv;
              };


              // Add the legend control to the map
              legendControl.addTo(map.value);


            })
            .catch(err => {


              if (err.response) {

                error.value = err.response.data.message ? err.response.data.message : 'Error occurred'


              } else if (err.request) {

                error.value = 'Failed to connect to the remote server'

              } else {
                // Anything else
                error.value = 'Failed to load data';
              }

            })
            .finally(() => loading.value = false)
      }

    }
    }


    const data = ref([])

    const loading = ref(false)

    const datasets = ref(null)

    const map = ref(null)

    const isFullScreen = ref(false)

    const fullscreen = ref(false)

    const parentElement=ref(null)


    // Generate a unique ID for this component instance
    const uniqueId = ref('map-' + Math.random().toString(36).substring(7)+new Date().getTime());


    const error = ref(null)

    onMounted(() => {


    })


    onBeforeMount(() => {
      if (map.value) {
        map.value.remove();
      }
    })



    // eslint-disable-next-line no-unused-vars
    watch(() => props.url, (newValue, oldValue) => {
      fetchData()
    });


    const visible = ref(false)
    return {
      data,
      loading,
      error,
      datasets,
      map,
      uniqueId,
      parentElement,
      isFullScreen,
      fullscreen,
      visible,
      onIntersection (entry) {
        visible.value = entry.isIntersecting
        fetchData()
      },
      // eslint-disable-next-line no-unused-vars

      toggle:() =>{
        // const element = document.documentElement; // Get the root DOM element (usually the whole page)
        const element = parentElement.value.$el; // Get the root DOM element (usually the whole page)

        if (document.fullscreenElement) {
          // If already in fullscreen, exit fullscreen
          document.exitFullscreen();
        } else {
          // If not in fullscreen, request fullscreen
          element.requestFullscreen()
              .catch(err => {
                console.error('Failed to enter fullscreen mode:', err);
              });
        }
      }
    }
  }

}

</script>

<style>

.fullscreen-wrapper {
  width: 100%;
  height: 100%;
  background: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.button {
  margin-bottom: 20px;
}

.container {
  z-index: 0;
}


.legend {
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  font-family: 'Open Sans', Helvetica, sans-serif;
  padding: 10px 14px;
  background-color: #FFFFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  min-width: 100px;
  border: 1px solid grey;
}

.legend p {
  font-size: 16px;
  line-height: 24px;
}

.legend img {
  max-width: 50px;
  margin: auto;
  display: block;
}


.legend-color {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

/* Define the CSS for the circles */
.custom-circle {

  border-radius: 50%;

  text-align: center;


  font-weight: bold;
}

/* Style for the legend box */
.legend-box {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  max-width: 300px;
  margin: 20px;
}

/* Style for legend item */
.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

/* Style for legend item color */
.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Style for legend item label */
.legend-label {
  flex: 1; /* Allow label to expand and take up remaining space */
}
</style>

import DateRangePicker from "@/components/DateRangePicker.vue";
import SummaryBox from "@/components/SummaryBox.vue";
import FormSelect from "@/components/FormSelect.vue";
import AnimatedMosquito from "@/components/AnimatedMosquito.vue";
import NoExperimentSelected from "@/components/NoExperimentSelected.vue";
import QuestionEditor from "@/components/QuestionEditor.vue";
import BarChart from "@/components/BarChart.vue";
import LineChart from "@/components/LineChart.vue";
import PieChart from "@/components/PieChart.vue";
import SummaryCard from "@/components/SummaryCard.vue";
import PhoneInput from "@/components/PhoneInput.vue";
import CountrySelect from "@/components/CountrySelect.vue";
import SelectableBox from "@/components/SelectableBox.vue";
import ContentEditor from "@/components/ContentEditor.vue";
import PivotTable from "@/components/PivotTable.vue";
import FormEditor from "@/components/FormEditor.vue";
import PieChartMap from "@/components/PieChartMap.vue";
import PivotTableAdv from "@/components/PivotTableAdv.vue";
import NormalTable from "@/components/NormalTable.vue";
import CircleMap from "@/components/CircleMap.vue";
import ChoroplethMap from "@/components/ChoroplethMap.vue";
import JsonEditorVue from 'json-editor-vue'
import MixedChart from "@/components/MixedChart.vue";
import TextView from "@/components/TextView.vue";
import DataFilter from "@/components/DataFilter.vue";
export default { DateRangePicker,SummaryBox,FormSelect,AnimatedMosquito,TextView,
    NoExperimentSelected,QuestionEditor,BarChart,LineChart,MixedChart,DataFilter,
    PieChart,SummaryCard,PhoneInput,CountrySelect,SelectableBox,ContentEditor,PivotTable,FormEditor,PieChartMap,PivotTableAdv,NormalTable,CircleMap,ChoroplethMap,JsonEditorVue};

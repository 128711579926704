<template>

  <q-list bordered class="rounded-borders q-pa-xs bg-white">
    <q-expansion-item

        expand-icon="expand_circle_down"
        :label="content.title"
        header-class="bg-white"
        expand-separator
    >

      <template v-slot:header>
        <q-item class="full-width">
          <q-item-section class="q-mr-none" avatar>
            <slot name="handle">
              <q-icon id="handle" name="drag_indicator" size="xs" color="grey-10" text-color="white"></q-icon>
            </slot>
          </q-item-section>
          <q-item-section side avatar>
            <q-checkbox :disable="!content.canDeactivate"
                        v-model="content.active" label="" dense>
            </q-checkbox>

          </q-item-section>

          <q-item-section>
            <q-item-label lines="1">{{ content.title }}</q-item-label>
            <q-item-label lines="1" class=" ">
              Type: <span class="text-primary cursor-pointer"> {{ content.type }}</span>
            </q-item-label>
          </q-item-section>


          <q-item-section side>
            <div class="text-grey-8 q-gutter-xs">
              <q-btn color="negative" :disable="!content.canDeactivate" @click="onContentDelete" class="gt-xs" flat
                     dense round icon="delete_outline"/>
            </div>
          </q-item-section>
        </q-item>
      </template>

      <q-card class="bg-grey-1 q-mt-sm q-mx-none" style="">
        <q-card-section class="q-pa-none">
          <table class="table full-width table-bordered q-mb-sm">

            <tbody class="align-center">


            <tr v-if="content.type!=='TextView'">
              <td style="" class="text-bold">Title:</td>
              <td colspan="11">
                {{ content.title }}
                <q-popup-edit
                    buttons v-model="content.title" :cover="false" :offset="[0, 10]"
                    v-slot="scope">
                  <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                           @keyup.enter.stop>
                    <template v-slot:prepend>
                      <q-icon name="edit" color="primary"/>
                    </template>
                  </q-input>
                </q-popup-edit>
              </td>
            </tr>

            <tr>
              <td style="" class="text-bold">Type:</td>
              <td colspan="11">
                {{ content.type }}
              </td>
            </tr>
            <tr v-if="content.type==='PivotTable' || content.type==='PivotTableAdv' || content.type==='NormalTable'">
              <td colspan="1" style="" class="text-bold">Width:</td>
              <td colspan="5" style="">
                {{ content.col }}
                <q-popup-edit
                    buttons v-model="content.col" :cover="false" :offset="[0, 10]"
                    v-slot="scope">
                  <q-input autogrow hint="Ranges from 1-12" type="number" color="primary" v-model="scope.value" dense
                           autofocus
                           counter
                           @keyup.enter.stop>
                    <template v-slot:prepend>
                      <q-icon name="edit" color="primary"/>
                    </template>
                  </q-input>
                </q-popup-edit>
              </td>

              <template
                  v-if="content.type==='PivotTableAdv' || content.type==='NormalTable' || content.type==='PivotTable'">
                <td colspan="1" style="" class="text-bold">Height:</td>
                <td colspan="5" style="">
                  {{ content.height }}
                  <q-popup-edit
                      buttons v-model="content.height" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow hint="In Px" type="number" color="primary" v-model="scope.value" dense autofocus
                             counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>
              </template>


            </tr>
            <tr v-else>
              <td colspan="1" style="" class="text-bold">Height:</td>
              <td colspan="5" style=";">
                {{ content.height }}
                <q-popup-edit
                    buttons v-model="content.height" :cover="false" :offset="[0, 10]"
                    v-slot="scope">
                  <q-input autogrow hint="in PX}" type="number" color="primary" v-model="scope.value" dense autofocus
                           counter
                           @keyup.enter.stop>
                    <template v-slot:prepend>
                      <q-icon name="edit" color="primary"/>
                    </template>
                  </q-input>
                </q-popup-edit>
              </td>

              <td colspan="1" style="" class="text-bold">Width:</td>
              <td colspan="5" style="">
                {{ content.col }}
                <q-popup-edit
                    buttons v-model="content.col" :cover="false" :offset="[0, 10]"
                    v-slot="scope">
                  <q-input autogrow hint="Ranges from 1-12" type="number" color="primary" v-model="scope.value" dense
                           autofocus
                           counter
                           @keyup.enter.stop>
                    <template v-slot:prepend>
                      <q-icon name="edit" color="primary"/>
                    </template>
                  </q-input>
                </q-popup-edit>
              </td>
            </tr>

            <template v-if="content.type!=='TextView'">
            <template
                v-if="content.type==='ChoroplethMap' || content.type==='PivotTable' || content.type==='PivotTableAdv' || content.type==='PieChartMap' || content.type==='CircleMap'">
              <template v-if="content.type==='PivotTable'">
                <tr>
                  <!--          <td colspan="6"></td>-->
                  <td colspan="1" style="" class="text-bold">Column Key:</td>
                  <td colspan="3" style="">
                    {{ content.columnKey }}
                    <q-popup-edit
                        buttons v-model="content.columnKey" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Row Key:</td>
                  <td colspan="3" style="">
                    {{ content.rowKey }}
                    <q-popup-edit
                        buttons v-model="content.rowKey" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Data Key:</td>
                  <td colspan="3" style="">
                    {{ content.dataKey }}
                    <q-popup-edit
                        buttons v-model="content.dataKey" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>

                </tr>

              </template>
              <template v-if="content.type==='PivotTableAdv'">
                <tr>
                  <!--          <td colspan="6"></td>-->
                  <td colspan="1" style="" class="text-bold">Column Keys:</td>
                  <td colspan="3" style="">
                    {{ content.columnKeys }}
                    <q-popup-edit
                        buttons v-model="content.columnKeys" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow hint="Keys separated by comas" color="primary" v-model="scope.value" dense
                               autofocus
                               counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Row Keys:</td>
                  <td colspan="3" style="">
                    {{ content.rowKeys }}
                    <q-popup-edit
                        buttons v-model="content.rowKeys" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow hint="Keys separated by comas" color="primary" v-model="scope.value" dense
                               autofocus
                               counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Data Key:</td>
                  <td colspan="3" style="">
                    {{ content.dataKey }}
                    <q-popup-edit
                        buttons v-model="content.dataKey" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>

                </tr>

              </template>
              <template v-if="content.type==='PieChartMap'">
                <tr>
                  <!--          <td colspan="6"></td>-->
                  <td colspan="1" style="" class="text-bold">Legend Title:</td>
                  <td colspan="3" style="">
                    {{ content.legendTitle }}
                    <q-popup-edit
                        buttons v-model="content.legendTitle" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Zoom:</td>
                  <td colspan="3" style="">
                    {{ content.zoom }}
                    <q-popup-edit
                        buttons v-model="content.zoom" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow type="number" color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="4" style="" class="text-bold">
                    <q-checkbox left-label v-model="content.showDoughnut" label="Show Doughnut Plot:" dense>
                    </q-checkbox>
                  </td>

                </tr>
                <tr>
                  <!--          <td colspan="6"></td>-->
                  <td colspan="1" style="" class="text-bold">Center Latitude:</td>
                  <td colspan="5" style="">
                    {{ content.centerLat }}
                    <q-popup-edit
                        buttons v-model="content.centerLat" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow type="number" color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Center Longitude:</td>
                  <td colspan="5" style="">
                    {{ content.centerLng }}
                    <q-popup-edit
                        buttons v-model="content.centerLng" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow type="number" color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                </tr>
                <tr v-if="content.type!='NormalTable'">
                  <td colspan="1" style="" class="text-bold">Label Key:</td>
                  <td colspan="3" style="">
                    {{ content.labelKey }}
                    <q-popup-edit
                        buttons v-model="content.labelKey" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Value Key:</td>
                  <td colspan="3" style="">
                    {{ content.valueKey }}
                    <q-popup-edit
                        buttons v-model="content.valueKey" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Location Key:</td>
                  <td colspan="3" style="">
                    {{ content.locationKey }}
                    <q-popup-edit
                        buttons v-model="content.locationKey" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                </tr>
              </template>
              <template v-if="content.type==='CircleMap' || content.type==='ChoroplethMap'">
                <tr>
                  <!--          <td colspan="6"></td>-->
                  <td colspan="1" style="" class="text-bold">Legend Title:</td>
                  <td colspan="5" style="">
                    {{ content.legend.title }}
                    <q-popup-edit
                        buttons v-model="content.legend.title" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Zoom:</td>
                  <td colspan="5" style="">
                    {{ content.zoom }}
                    <q-popup-edit
                        buttons v-model="content.zoom" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow type="number" color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                </tr>
                <tr>
                  <!--          <td colspan="6"></td>-->
                  <td colspan="1" style="" class="text-bold">Center Latitude:</td>
                  <td colspan="5" style="">
                    {{ content.centerLat }}
                    <q-popup-edit
                        buttons v-model="content.centerLat" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow type="number" color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Center Longitude:</td>
                  <td colspan="5" style="">
                    {{ content.centerLng }}
                    <q-popup-edit
                        buttons v-model="content.centerLng" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow type="number" color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                </tr>

                <template v-if="content.type==='CircleMap'">
                  <tr v-if="content.type!='NormalTable'">
                    <td colspan="1" style="" class="text-bold">Value Key:</td>
                    <td colspan="3" style="">
                      {{ content.valueKey }}
                      <q-popup-edit
                          buttons v-model="content.valueKey" :cover="false" :offset="[0, 10]"
                          v-slot="scope">
                        <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                                 @keyup.enter.stop>
                          <template v-slot:prepend>
                            <q-icon name="edit" color="primary"/>
                          </template>
                        </q-input>
                      </q-popup-edit>
                    </td>
                    <td colspan="1" style="" class="text-bold">Location Key:</td>
                    <td colspan="3" style="">
                      {{ content.locationKey }}
                      <q-popup-edit
                          buttons v-model="content.locationKey" :cover="false" :offset="[0, 10]"
                          v-slot="scope">
                        <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                                 @keyup.enter.stop>
                          <template v-slot:prepend>
                            <q-icon name="edit" color="primary"/>
                          </template>
                        </q-input>
                      </q-popup-edit>
                    </td>
                    <td colspan="4" style="">
                      <q-checkbox left-label v-model="content.showCircleLabel" label="Show Label on Circle:" dense>
                      </q-checkbox>
                    </td>
                  </tr>
                </template>
                <template v-if="content.type==='ChoroplethMap'">
                  <tr>
                    <td colspan="1" style="" class="text-bold">GeoJSON:</td>
                    <td colspan="11">
                      <q-select outlined v-model="content.geoJSON" class="q-pa-none q-ma-none" style="width: 50%"
                                option-label="name" dense options-dense :options="geojsons "></q-select>
                    </td>
                  </tr>
                  <tr v-if="content.type!='NormalTable'">
                    <td colspan="1" style="" class="text-bold">Label Key:</td>
                    <td colspan="3" style="">
                      {{ content.labelKey }}
                      <q-popup-edit
                          buttons v-model="content.labelKey" :cover="false" :offset="[0, 10]"
                          v-slot="scope">
                        <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                                 @keyup.enter.stop>
                          <template v-slot:prepend>
                            <q-icon name="edit" color="primary"/>
                          </template>
                        </q-input>
                      </q-popup-edit>
                    </td>
                    <td colspan="1" style="" class="text-bold">Value Key:</td>
                    <td colspan="3" style="">
                      {{ content.valueKey }}
                      <q-popup-edit
                          buttons v-model="content.valueKey" :cover="false" :offset="[0, 10]"
                          v-slot="scope">
                        <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                                 @keyup.enter.stop>
                          <template v-slot:prepend>
                            <q-icon name="edit" color="primary"/>
                          </template>
                        </q-input>
                      </q-popup-edit>
                    </td>
                    <td colspan="1" style="" class="text-bold">Feature ID Key:</td>
                    <td colspan="3" style="">
                      {{ content.featureIDKey }}
                      <q-popup-edit
                          buttons v-model="content.featureIDKey" :cover="false" :offset="[0, 10]"
                          v-slot="scope">
                        <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                                 @keyup.enter.stop>
                          <template v-slot:prepend>
                            <q-icon name="edit" color="primary"/>
                          </template>
                        </q-input>
                      </q-popup-edit>
                    </td>
                  </tr>
                </template>

                <tr class="shadow-1 text-white" style="background-color: #888888">
                  <td colspan="12" class="text-bold">
                    <div class="row q-ma-none q-pa-none">
                      <div class="text-bold">Legend Options</div>
                      <q-space></q-space>
                      <q-btn dense @click="addLegendOption" size="sm" no-caps
                             flat
                             icon="add"></q-btn>

                    </div>
                  </td>
                </tr>


                <template v-if="content.type==='CircleMap'">
                  <tr class="bg-grey-2 text-bold">
                    <td colspan="3" style="">Label</td>
                    <td colspan="1" style="">Fill Color</td>
                    <td colspan="1">Text Color</td>
                    <td colspan="1">Outline Color</td>
                    <td colspan="1">Radius</td>
                    <td colspan="2">Min Value</td>
                    <td colspan="2">Max Value</td>
                    <td colspan="1" style="">
                      <div class="row q-ma-none q-pa-none">
                        <div class="text-bold"></div>
                        <q-space></q-space>
                        <q-btn dense size="sm" no-caps flat color="white" icon="clear"></q-btn>
                      </div>

                    </td>
                  </tr>

                  <tr v-for="(legendOption,index) in content.legend.options" :key="index" class="">

                    <td colspan="3" style="">
                      <q-input type="text" outlined dense v-model="legendOption.label"></q-input>
                    </td>

                    <td colspan="1" style="">
                      <div class="rounded-borders shadow-1"
                           :style="`background:${legendOption.color?legendOption.color:'black'};height:26px;width:100%; cursor:pointer;border:1px solid white`">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                          <q-color v-model="legendOption.color"/>
                        </q-popup-proxy>
                      </div>
                    </td>

                    <td colspan="1" style="">
                      <div class="rounded-borders shadow-1"
                           :style="`background:${legendOption.textColor?legendOption.textColor:'white'};height:26px;width:100%; cursor:pointer;border:1px solid white`">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                          <q-color v-model="legendOption.textColor"/>
                        </q-popup-proxy>
                      </div>
                    </td>

                    <td colspan="1">
                      <div class="rounded-borders shadow-1"
                           :style="`background:${legendOption.borderColor?legendOption.borderColor:'black'};height:26px;width:100%; cursor:pointer;border:1px solid white`">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                          <q-color v-model="legendOption.borderColor"/>
                        </q-popup-proxy>
                      </div>
                    </td>

                    <td colspan="1" style="">
                      <q-input type="number" min="1" outlined dense v-model="legendOption.radius"></q-input>
                    </td>

                    <td colspan="2" style="">
                      <q-input type="number" outlined dense v-model="legendOption.min"></q-input>
                    </td>

                    <td colspan="2" style="">
                      <q-input type="number" outlined dense v-model="legendOption.max"></q-input>
                    </td>

                    <td colspan="1" style="">
                      <div class="row q-ma-none q-pa-none">
                        <div class="text-bold"></div>
                        <q-space></q-space>
                        <q-btn @click="removeLegendOption(evt,index) " dense size="sm"
                               no-caps
                               flat color="negative" icon="clear"></q-btn>
                      </div>

                    </td>


                  </tr>
                </template>
                <template v-if="content.type==='ChoroplethMap'">
                  <tr class="bg-grey-2 text-bold">
                    <td colspan="4" style="">Label</td>
                    <td colspan="1" style="">Fill Color</td>
                    <td colspan="3">Min Value</td>
                    <td colspan="3">Max Value</td>
                    <td colspan="1" style="">
                      <div class="row q-ma-none q-pa-none">
                        <div class="text-bold"></div>
                        <q-space></q-space>
                        <q-btn dense size="sm" no-caps flat color="white" icon="clear"></q-btn>
                      </div>

                    </td>
                  </tr>

                  <tr v-for="(legendOption,index) in content.legend.options" :key="index" class="">

                    <td colspan="4" style="">
                      <q-input type="text" outlined dense v-model="legendOption.label"></q-input>
                    </td>

                    <td colspan="1" style="">
                      <div class="rounded-borders shadow-1"
                           :style="`background:${legendOption.color?legendOption.color:'black'};height:26px;width:100%; cursor:pointer;border:1px solid white`">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                          <q-color v-model="legendOption.color"/>
                        </q-popup-proxy>
                      </div>
                    </td>


                    <td colspan="3" style="">
                      <q-input type="number" outlined dense v-model="legendOption.min"></q-input>
                    </td>

                    <td colspan="3" style="">
                      <q-input type="number" outlined dense v-model="legendOption.max"></q-input>
                    </td>

                    <td colspan="1" style="">
                      <div class="row q-ma-none q-pa-none">
                        <div class="text-bold"></div>
                        <q-space></q-space>
                        <q-btn @click="removeLegendOption(evt,index) " dense size="sm"
                               no-caps
                               flat color="negative" icon="clear"></q-btn>
                      </div>

                    </td>


                  </tr>
                </template>
              </template>

            </template>


            <template v-else>
              <tr v-if="content.type!=='NormalTable'">
                <td colspan="1" style="" class="text-bold">Label Key:</td>
                <td colspan="5" style="">
                  {{ content.labelKey }}
                  <q-popup-edit
                      buttons v-model="content.labelKey" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>
                <td colspan="1" style="" class="text-bold">Value Key:</td>
                <td colspan="5" style="">
                  {{ content.valueKey }}
                  <q-popup-edit
                      buttons v-model="content.valueKey" :cover="false" :offset="[0, 10]"
                      v-slot="scope">
                    <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                             @keyup.enter.stop>
                      <template v-slot:prepend>
                        <q-icon name="edit" color="primary"/>
                      </template>
                    </q-input>
                  </q-popup-edit>
                </td>
              </tr>
            </template>

            <template
                v-if="content.type==='BarChart' || content.type==='TimeSeriesGraph' || content.type==='LineGraph' || content.type==='LineChart' || content.type==='MixedChart' || content.type==='PieChart'">

              <template v-if="content.type!=='PieChart'">
                <tr>
                  <td colspan="1" style="" class="text-bold">X-axis Label:</td>
                  <td colspan="5" style="">
                    {{ content.scale.xLabel }}
                    <q-popup-edit
                        buttons v-model="content.scale.xLabel" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Y-axis Label:</td>
                  <td colspan="5" style="">
                    {{ content.scale.yLabel }}
                    <q-popup-edit
                        buttons v-model="content.scale.yLabel" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                </tr>

                <tr>
                  <td :colspan="content.type==='BarChart' || content.type==='LineChart' || content.type==='MixedChart'?4:12" style="">
                    <q-checkbox left-label v-model="content.displayTargetLine" label="Show Target Line" dense>

                    </q-checkbox>
                  </td>
                  <td v-if="content.type==='BarChart'" colspan="2" style="">
                    <q-checkbox left-label v-model="content.stacked" label="Stacked" dense>
                    </q-checkbox>
                  </td>
                  <td v-if="content.type==='BarChart'" colspan="2" style="">
                    <q-checkbox left-label v-model="content.stacked100" label="Stacked100" dense>
                    </q-checkbox>
                  </td>
                  <td v-if="content.type==='BarChart' || content.type==='LineChart' || content.type==='MixedChart'" colspan="4" style="">



                    <q-select dense outlined v-model="content.indexAxis"
                               class="q-pa-none q-ma-none"  options-dense :options="['X','Y']">
                      <template v-slot:before>
                        <span style="font-size: 0.6em" class="text-bold text-black" >Index Axis:</span>
                      </template>
                    </q-select>
                  </td>
                </tr>
                <tr v-if="content.displayTargetLine">
                  <td colspan="1" style="" class="text-bold">Target Line Value</td>
                  <td colspan="3" style="">
                    {{ content.targetLineYPoint }}
                    <q-popup-edit
                        buttons v-model="content.targetLineYPoint" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow type="number" color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Target Line Width</td>
                  <td colspan="3" style="">
                    {{ content.targetLineWidth }}
                    <q-popup-edit
                        buttons v-model="content.targetLineWidth" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow type="number" color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Target Line Color</td>
                  <td colspan="3" style="">
                    <div
                        class="rounded-borders shadow-1"
                        :style="`background:${content.targetLineColor?content.targetLineColor:'red'};height:12px;width:60px;cursor:pointer;border:1px solid white`">
                      <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-color v-model="content.targetLineColor"/>
                      </q-popup-proxy>
                    </div>


                  </td>
                </tr>
                <tr v-if="content.displayTargetLine">
                  <td colspan="12" style="">
                    <q-checkbox left-label v-model="content.showTargetLineLabel" label="Show Target Line Label" dense>

                    </q-checkbox>
                  </td>
                </tr>
                <tr v-if="content.showTargetLineLabel && content.displayTargetLine">

                  <td colspan="1" style="" class="text-bold">Target Line Label</td>
                  <td colspan="5" style="">
                    {{ content.targetLineLabel }}
                    <q-popup-edit
                        buttons v-model="content.targetLineLabel" :cover="false" :offset="[0, 10]"
                        v-slot="scope">
                      <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                               @keyup.enter.stop>
                        <template v-slot:prepend>
                          <q-icon name="edit" color="primary"/>
                        </template>
                      </q-input>
                    </q-popup-edit>
                  </td>
                  <td colspan="1" style="" class="text-bold">Target Line Label Color</td>
                  <td colspan="5" style="">
                    <div
                        class="rounded-borders shadow-1"
                        :style="`background:${content.targetLineLabelColor?content.targetLineLabelColor:'red'};height:12px;width:60px;cursor:pointer;border:1px solid white`">
                      <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-color v-model="content.targetLineLabelColor"/>
                      </q-popup-proxy>
                    </div>


                  </td>
                </tr>

              </template>
              <tr>
                <td colspan="4" style="">

                  <q-select  outlined v-model="content.legendPosition"
                             class="q-pa-none q-ma-none" dense options-dense :options="['Top','Right','Bottom','Left']">
                    <template v-slot:before>
                      <span style="font-size: 0.6em" class="text-bold text-black" >Legend Position:</span>
                    </template>
                  </q-select>
                </td>
                <td colspan="4" style="">

                      <q-select  outlined v-model="content.legendFontStyle"
                                 class="q-pa-none q-ma-none" dense options-dense :options="['Normal','Italic']">
                        <template v-slot:before>
                          <span style="font-size: 0.6em" class="text-bold text-black" >Legend Font Style:</span>
                        </template>
                      </q-select>

                </td>
                <td colspan="4" style="">
                  <q-select  outlined v-model="content.legendFontWeight"
                             class="q-pa-none q-ma-none" dense options-dense :options="['Normal','Bold']">
                    <template v-slot:before>
                      <span style="font-size: 0.6em" class="text-bold text-black" >Legend Font Weight:</span>
                    </template>
                  </q-select>

                </td>
              </tr>

            </template>


            <template
                v-if="content.type==='BarChart' || content.type==='TimeSeriesGraph' || content.type==='LineGraph' || content.type==='LineChart'  || content.type==='MixedChart'">
              <tr class="shadow-1 text-white" style="background-color: #888888">

                <td colspan="12" class="text-bold">
                  <div class="row q-ma-none q-pa-none">
                    <div class="text-bold">Datasets</div>
                    <q-space></q-space>
                    <q-btn dense @click="addDataset" size="sm" no-caps
                           flat
                           icon="add"></q-btn>

                  </div>
                </td>
              </tr>


              <tr class="bg-grey-2 text-bold">
                <td :colspan="content.type==='MixedChart'?3:4" style="">Title</td>
                <td v-if="content.type==='MixedChart'" colspan="2" style="">Type</td>
                <td :colspan="content.type==='MixedChart'?6:7" style="">Indicator</td>
                <td colspan="1" style="">
                  <!--                  <q-btn dense size="sm" no-caps flat color="white" icon="clear"></q-btn>-->
                </td>
              </tr>

              <tr v-for="(dataset,index) in content.datasets" :key="index" class="">

                <td :colspan="content.type==='MixedChart'?3:4" style="">
                  <q-input type="text" outlined dense v-model="dataset.title"></q-input>
                </td>
                <td v-if="content.type==='MixedChart'" colspan="2" style="">

                  <q-select outlined v-model="dataset.type"
                            class="q-pa-none q-ma-none" dense options-dense :options="['Bar','Line']">
                  </q-select>
                </td>
                <td :colspan="content.type==='MixedChart'?7:8" style="">
                  <div class="row q-ma-none q-pa-none">
                    <q-select cl outlined v-model="dataset.indicator" class="q-pa-none q-ma-none" style="width: 50%"
                              option-label="name"
                              dense
                              options-dense :options="indicators "></q-select>
                    <q-btn no-caps padding="5px 20px 5px 20px" color="grey-8" class="q-ml-xs" dense outline
                           label="Filter" icon="filter_alt"
                           icon-right="expand_more">
                      <q-popup-proxy style="border:1px solid #c5c5c5" class=""
                                     transition-show="flip-up" transition-hide="scale">
                        <q-banner class="q-ma-none q-pa-none">
                          <q-card style="min-width: 500px" class="shadow-0 q-ma-xs">
                            <q-card-section class="q-pa-none">
                              <div v-if="dataset.filter && dataset.filter.length>0" class="full-width">
                                <table class="full-width" style="border-collapse: collapse;border: 1px solid #69899F;">
                                  <thead>
                                  <tr>
                                    <th class="text-left"
                                        style="border-color: grey;border: 1px solid #69899F;width: 35%">Key
                                    </th>
                                    <th style="border-color: grey;border: 1px solid #69899F;width: 25%">Operator</th>
                                    <th class="text-left"
                                        style="border-color: grey;border: 1px solid #69899F;width: 35%">Value
                                    </th>
                                    <th class="text-center"
                                        style="border-color: grey;border: 1px solid #69899F;width: 2%">
                                      <q-icon name="clear"></q-icon>
                                    </th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(filterOption,filterIndex) in dataset.filter" :key="filterIndex">
                                    <td class="text-left q-ma-xs q-pa-xs">
                                      {{ filterOption.key }}
                                      <q-popup-edit
                                          buttons v-model="filterOption.key" :cover="false" :offset="[0, 10]"
                                          v-slot="scope">
                                        <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                                                 @keyup.enter.stop>
                                          <template v-slot:prepend>
                                            <q-icon name="edit" color="primary"/>
                                          </template>
                                        </q-input>
                                      </q-popup-edit>
                                    </td>
                                    <td class=" q-ma-xs q-pa-xs">
                                      <q-select borderless dense v-model="filterOption.operator" :options="operators"/>

                                    </td>
                                    <td class="text-left q-ma-xs q-pa-xs">
                                      {{ filterOption.value }}
                                      <q-popup-edit
                                          buttons v-model="filterOption.value" :cover="false" :offset="[0, 10]"
                                          v-slot="scope">
                                        <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                                                 @keyup.enter.stop>
                                          <template v-slot:prepend>
                                            <q-icon name="edit" color="primary"/>
                                          </template>
                                        </q-input>
                                      </q-popup-edit>
                                    </td>
                                    <td class="q-ma-xs q-pa-xs">
                                      <q-btn @click="removeFilterOption(evt,{dataset,filterIndex}) " dense size="sm"
                                             no-caps
                                             flat color="negative" icon="clear"></q-btn>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div v-else class="full-width text-center q-my-lg">
                                No filter option found
                              </div>
                            </q-card-section>
                            <q-separator></q-separator>

                            <q-card-section class="q-pa-none">
                              <q-btn dense outline @click="addFilterOption(evnt,dataset)" class="q-mt-sm bg-grey-2"
                                     padding="2px 10px 2px 10px"
                                     label="New Option" no-caps></q-btn>
                            </q-card-section>
                          </q-card>
                        </q-banner>
                      </q-popup-proxy>
                    </q-btn>
                    <q-space></q-space>
                    <div class="row q-ma-none q-pa-none">
                      <div class="text-bold"></div>
                      <q-space></q-space>
                      <q-btn @click="removeDataset(evt,index) " dense size="sm"
                             no-caps
                             flat color="negative" icon="clear"></q-btn>

                    </div>
                  </div>
                </td>


              </tr>

            </template>
            <template
                v-else>
              <tr class="shadow-1 text-white" style="background-color: #888888">

                <td colspan="12" class="text-bold">
                  <div class="row q-ma-none q-pa-none">
                    <div class="text-bold">Datasets</div>
                    <q-space></q-space>
                    <q-btn dense @click="addDataset" size="sm" no-caps
                           flat
                           icon="add"></q-btn>

                  </div>
                </td>
              </tr>


              <tr class="bg-grey-2 text-bold">
                <td colspan="12">
                  <div class="row q-ma-none q-pa-none">
                    <div class="text-bold">Indicator</div>
                    <q-space></q-space>
                    <q-btn dense size="sm" no-caps flat color="white" icon="clear"></q-btn>
                  </div>
                </td>


              </tr>

              <tr v-for="(dataset,index) in content.datasets" :key="index" class="">

                <td colspan="12">
                  <div class="row q-ma-none q-pa-none">

                    <q-select outlined v-model="dataset.indicator" class="q-pa-none q-ma-none" style="width: 50%"
                              option-label="name" dense options-dense :options="indicators "></q-select>


                    <q-btn no-caps padding="5px 20px 5px 20px" color="grey-8" class="q-ml-xs" dense outline
                           label="Filter" icon="filter_alt"
                           icon-right="expand_more">
                      <q-popup-proxy style="border:1px solid #c5c5c5" class=""
                                     transition-show="flip-up" transition-hide="scale">
                        <q-banner class="q-ma-none q-pa-none">
                          <q-card style="min-width: 500px" class="shadow-0 q-ma-xs">
                            <q-card-section class="q-pa-none">
                              <div v-if="dataset.filter && dataset.filter.length>0" class="full-width">
                                <table class="full-width" style="border-collapse: collapse;border: 1px solid #69899F;">
                                  <thead>
                                  <tr>
                                    <th class="text-left"
                                        style="border-color: grey;border: 1px solid #69899F;width: 35%">Key
                                    </th>
                                    <th style="border-color: grey;border: 1px solid #69899F;width: 25%">Operator</th>
                                    <th class="text-left"
                                        style="border-color: grey;border: 1px solid #69899F;width: 35%">Value
                                    </th>
                                    <th class="text-center"
                                        style="border-color: grey;border: 1px solid #69899F;width: 2%">
                                      <q-icon name="clear"></q-icon>
                                    </th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(filterOption,filterIndex) in dataset.filter" :key="filterIndex">
                                    <td class="text-left">
                                      {{ filterOption.key }}
                                      <q-popup-edit
                                          buttons v-model="filterOption.key" :cover="false" :offset="[0, 10]"
                                          v-slot="scope">
                                        <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                                                 @keyup.enter.stop>
                                          <template v-slot:prepend>
                                            <q-icon name="edit" color="primary"/>
                                          </template>
                                        </q-input>
                                      </q-popup-edit>
                                    </td>
                                    <td>
                                      <q-select borderless dense v-model="filterOption.operator" :options="operators"/>

                                    </td>
                                    <td class="text-left">
                                      {{ filterOption.value }}
                                      <q-popup-edit
                                          buttons v-model="filterOption.value" :cover="false" :offset="[0, 10]"
                                          v-slot="scope">
                                        <q-input autogrow color="primary" v-model="scope.value" dense autofocus counter
                                                 @keyup.enter.stop>
                                          <template v-slot:prepend>
                                            <q-icon name="edit" color="primary"/>
                                          </template>
                                        </q-input>
                                      </q-popup-edit>
                                    </td>
                                    <td>
                                      <q-btn @click="removeFilterOption(evt,{dataset,filterIndex}) " dense size="sm"
                                             no-caps
                                             flat color="negative" icon="clear"></q-btn>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div v-else class="full-width text-center q-my-lg">
                                No filter option found
                              </div>
                            </q-card-section>
                            <q-separator></q-separator>
                            <q-card-section class="q-pa-none">
                              <q-btn dense outline @click="addFilterOption(evnt,dataset)" class="q-mt-sm bg-grey-2"
                                     padding="2px 10px 2px 10px"
                                     label="New Option" no-caps></q-btn>
                            </q-card-section>
                          </q-card>
                        </q-banner>
                      </q-popup-proxy>
                    </q-btn>
                    <q-space></q-space>
                    <div class="row q-ma-none q-pa-none">
                      <div class="text-bold"></div>
                      <q-space></q-space>
                      <q-btn @click="removeDataset(evt,index) " dense size="sm"
                             no-caps
                             flat color="negative" icon="clear"></q-btn>

                    </div>
                  </div>

                </td>


              </tr>

            </template>
            </template>
            <template v-else>
              <tr>
              <td colspan="1" style="" class="text-bold">Text:</td>
              <td colspan="11" style="">
                <q-editor v-model="content.text"      :fonts="{
        arial: 'Arial',
        arial_black: 'Arial Black',
        comic_sans: 'Comic Sans MS',
        courier_new: 'Courier New',
        impact: 'Impact',
        lucida_grande: 'Lucida Grande',
        times_new_roman: 'Times New Roman',
        verdana: 'Verdana'
      }"   :toolbar="[

        ['bold', 'italic', 'strike', 'underline'],
                [
          {
            label: $q.lang.editor.align,
            icon: $q.iconSet.editor.align,
            fixedLabel: true,
              list: 'only-icons',
            options: ['left', 'center', 'right', 'justify']
          }
        ],
        [
           {
            label: $q.lang.editor.fontSize,
            icon: $q.iconSet.editor.fontSize,
            fixedIcon: true,
            list: 'no-icons',
            options: [
              'size-1',
              'size-2',
              'size-3',
              'size-4',
              'size-5',
              'size-6',
              'size-7'
            ]
          }
        ],
        [ {
            label: $q.lang.editor.defaultFont,
            icon: $q.iconSet.editor.font,
            fixedIcon: true,
            list: 'no-icons',
            options: [
              'default_font',
              'arial',
              'arial_black',
              'comic_sans',
              'courier_new',
              'impact',
              'lucida_grande',
              'times_new_roman',
              'verdana'
            ]
          }
        ],
          ['undo', 'redo'],
        ['viewsource']
      ]" />
              </td>
              </tr>
            </template>
            <tr style="background-color: #F5F5F5">
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
              <td style="width: 8.3%"></td>
            </tr>
            </tbody>
          </table>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </q-list>
</template>

<script>
import {useQuasar} from "quasar";

import {ref, onMounted, watch} from "vue";

export default {

  name: 'ContentEditor',

  emits: ['update:modelValue', 'delete'],

  props: {
    modelValue: {
      required: true,
      type: Object
    },

    indicators: {
      type: Array,
      default: () => [
        {
          name: "Jma",
          code: 1,
        },
        {
          name: "SA",
          code: 2,
        }
      ]
    },
    geojsons: {
      type: Array,
      default: () => [
        {
          name: "Jma",
          code: 1,
        }
      ]
    }
  },


  // eslint-disable-next-line
  setup(props, {emit}) {

    const $q = useQuasar()

    const content = ref({})
    const operators = ref([
      "TEXT_EQUAL",
      "NUMERIC_EQUAL",
      "GREATER_THAN",
      "GREATER_THAN_EQUAL",
      "LESS_THAN",
      "LESS_THAN_EQUAL",
      "IS_IN_TEXT",
      "IS_IN_NUMERIC",
      "IS_NOT_IN_TEXT",
      "IS_NOT_IN_NUMERIC"
    ])

    onMounted(() => {
      content.value = props.modelValue;
    })


    // eslint-disable-next-line no-unused-vars
    watch(() => content.value, (newValue, oldValue) => {
      emit('update:modelValue', newValue)
    }, {deep: true});

    return {
      content,
      operators,
      onContentDelete(event) {
        $q.dialog({
          title: 'Confirm',
          message: 'Would you like to delete this content?',
          cancel: true,
          persistent: true
        }).onOk(() => {
          emit('delete')
        })

        event.stopPropagation()
      },
      addFilterOption(evnt, dataset) {
        if (dataset.filter) {
          dataset.filter.push({
            operator: "TEXT_EQUAL"
          });
        } else {
          dataset.filter = [
            {
              operator: "TEXT_EQUAL"
            }
          ];
        }

      },

      removeFilterOption(evt, {dataset, index}) {
        dataset.filter.splice(index, 1);
      },

      addDataset() {
        if (content.value.datasets) {
          content.value.datasets.push({
            indicator: null,
            filter: []
          });
        } else {
          content.value.datasets = [
            {
              indicator: null,
              filter: []
            }
          ];
        }

      },
      addLegendOption() {
        if (content.value.legend.options) {
          content.value.legend.options.push({

            min: 0,
            max: 10,
            color: "black",
            label: "Label",
            radius: 30,
            textColor: "white",
            borderColor: "black"

          });
        } else {
          content.value.legend.options = [
            {
              min: 0,
              max: 10,
              color: "black",
              label: "Label",
              radius: 30,
              textColor: "white",
              borderColor: "black"
            }
          ];
        }

      },

      removeDataset(evt, index) {
        content.value.datasets.splice(index, 1);
      },
      removeLegendOption(evt, index) {
        content.value.legend.options.splice(index, 1);
      },
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  border-collapse: collapse;
  border: 1px solid #69899F;
}

table td {
  border: 1px dotted #000000;
  padding: 5px;
}

table td:first-child {
  border-left: 0px solid #000000;
}

table th {
  border: 2px solid #69899F;
  padding: 5px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.item {
  border: 1px solid #575757;
  margin: 0.1rem;
}

.sortable-ghost {
  border: 1px dashed blue;
  font-size: 0;
  overflow: hidden;
}

#handle:hover {
  cursor: move;
}
</style>

<template>
  <q-card v-intersection.once="onIntersection" ref="parentElement" :style="{'height': `${schema.height}px`}"
          class="rounded-borders bg-white shadow-0 q-mb-sm q-pb-lg">
    <q-card-section class="q-py-xs  q-pr-xs">
      <div class="row items-center no-wrap">
        <div class="col full-height">
          <q-item-label class="text-bold" style="font-size: 1.0em">{{ schema.title }}</q-item-label>
        </div>
        <div class="col-auto full-height">
          <div class="q-gutter-xs">
            <q-btn v-show="isFullScreen" @click="toggle" dense color="grey-7" flat padding="0 5px 0 5px"
                   icon="fullscreen_exit" :disable="loading"></q-btn>
            <q-btn v-show="!isFullScreen" dense flat padding="0 5px 0 5px" icon="more_vert" :disable="loading">
              <q-menu square transition-show="jump-down"
                      transition-hide="jump-up" auto-close>
                <q-list dense style="min-width: 100px">
                  <q-item @click="toggle" dense clickable>

                    <q-item-section>
                      <q-item-label lines="1" header class="q-ma-none q-pa-none text-black">
                        <div>
                          <q-icon size="sm" :name="`${isFullScreen?'fullscreen_exit':'fullscreen'}`"/>
                          {{ `${!isFullScreen ? 'View Fullscreen' : 'Exit Fullscreen'}` }}
                        </div>
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
      </div>
    </q-card-section>
    <q-separator></q-separator>
    <q-card-section v-if="error" class="full-height">
      <div :style="{'min-height':`${schema.height+'px'}`}"
           class="q-gutter-md row justify-center items-center flex full-height">
        <div class="q-gutter-sm text-center">
          <q-icon name="warning" size="md" color="red"/>
          <p class="justify-center text-center">
            {{ error }}
          </p>
        </div>
      </div>
    </q-card-section>
    <q-card-section v-else class="full-height">
      <div :style="{'min-height':`${schema.height+'px'}`}" v-if="loading"
           class="q-gutter-md row justify-center items-center flex full-height">
        <div>
          <q-spinner
              color="primary"
              size="3em"
              :thickness="5"
          ></q-spinner>
        </div>
      </div>

      <Pie v-else :data="data" :options="options"/>

    </q-card-section>
  </q-card>
</template>

<script lang="js">
import {
  Chart, ArcElement, Tooltip, Legend
} from 'chart.js'
import {Pie} from 'vue-chartjs'
import {onMounted, ref, watch} from 'vue'

import axios from "axios";
import config from "@/config";

Chart.register(ArcElement, Tooltip, Legend)
export default {

  name: 'BarChart',
  components: {
    Pie
  },
  props: {
    schema: {
      type: Object,
      require: true
    },
    url: String,
    headers: Array,
    withCredentials: {
      type: Boolean,
      default: false,
    }
  },

  // eslint-disable-next-line
  setup(props, {emit}) {


    // Detect changes in fullscreen mode
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        isFullScreen.value = true;
      } else {
        isFullScreen.value = false;
      }
    });


    const searchValueByLabel = (arr, label) => {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][props.schema.labelKey] === label) {
          return arr[i][props.schema.valueKey];
        }
      }
      return 0;
    }


    const getRandomColor = () => {

      // const categoricalColors = chroma.scale(['#f8f856','#340010']).mode('lch').colors(count)

      const categoricalColors = [
        "#0065BA",
        "#661D32",
        "#888886",
        "#C00630",
        "#002F6E",
        "#1D2722",
        "#6A6463",
        "#83c2d5",
        "#66e443",
        "#c4b231",
        "#786934",
        "#63a73a",
        "#6dd5d0",
        "#c546e3",
        "#d89079",
        "#d5325c",
        "#5841d3",
        "#9d7470",
        "#dae42e",
        "#447732",
        "#c09dd6",
        "#619bdc",
        "#d07035",
        "#e23a29",
        "#442875",
        "#545983",
        "#cba7be",
        "#996db7",
        "#6b978e",
        "#b9b063",
        "#5de0ab",
        "#e03dad",
        "#cf696d",
        "#df9b2d",
        "#55d46c",
        "#824422",
        "#372241",
        "#3b3127",
        "#bb8447",
        "#a13ca9",
        "#586370",
        "#7dcb7c",
        "#6eb187",
        "#da80db",
        "#3d5534",
        "#84366d",
        "#9b64e2",
        "#c0d96f",
        "#cf6597",
        "#9fd844",
        "#b33c34",
        "#bdce8c",
        "#4f2197",

        "#cec0a3",
        "#698fa5"
      ]


      // Print the generated colors to the console
      console.log(categoricalColors);

      let letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        // eslint-disable-next-line no-unused-vars
        color += letters[Math.floor(Math.random() * 16)];
      }
      return categoricalColors;
    }

    const fetchData = () => {
      if (visible.value) {
        // loading.value = true

        datasets.value = props.schema.datasets;

        //Create dataset query request without including query

        let datasetRequests = []

        for (let [sequenceNumber, dataset] of datasets.value.entries()) {

          let datasetRequest = {};

          datasetRequest.sequenceNumber = sequenceNumber;

          datasetRequest.indicator = {
            code: dataset.indicator.code
          };

          datasetRequest.filter = dataset.filter ? dataset.filter : []

          datasetRequests.push(datasetRequest);

        }

        //Set to true if you want to test locally with sample data
        const testLocal = false;

        if (!testLocal) {

          error.value = null;

          loading.value = true

          axios.create({
            baseURL: config.baseUrl,
            timeout: 0,
            //Comment this when want to run as SPA
            withCredentials: true,
          })
              .post(`${props.url}`, datasetRequests, {
                headers: {}
              })

              // eslint-disable-next-line no-unused-vars
              .then(response => {

                let res = response.data;


                for (let [sequenceNumber, dataset] of datasets.value.entries()) {
                  const result = res.find(obj => obj.indicator.code === dataset.indicator.code && obj.sequenceNumber === sequenceNumber);
                  dataset.data = result.data;
                }

                let formattedLabels = []

                let labels = []
                for (let item of datasets.value) {
                  labels.push(...item.data.map(item => item[props.schema.labelKey]))
                  formattedLabels = [...new Set(labels)];
                }


                let formattedDatasets = []

                for (let item of datasets.value) {
                  let datum = {
                    backgroundColor: [],
                    data: []
                  }

                  for (let label of formattedLabels) {
                    datum.data.push(searchValueByLabel(item.data, label))
                  }

                  datum.backgroundColor = getRandomColor()

                  formattedDatasets.push(datum)

                }

                data.value = {
                  labels: formattedLabels,
                  datasets: formattedDatasets
                }
              })
              .catch(err => {


                if (err.response) {

                  error.value = err.response.data.message ? err.response.data.message : 'Error occurred'


                } else if (err.request) {

                  error.value = 'Failed to connect to the remote server'

                } else {
                  // Anything else
                  error.value = 'Failed to load data';
                }

              })
              .finally(() => loading.value = false)
        } else {

          let res = [
            {
              "indicator": {
                "code": "1"
              },
              "sequenceNumber": 0,
              "data": [
                {
                  "Species": "An.arabiensis",
                  "Total": 3778.0
                },
                {
                  "Species": "An.gambiae s.s",
                  "Total": 448.0
                },
                {
                  "Species": "An.gambiae s.s/An.arabiensis",
                  "Total": 8.0
                },
                {
                  "Species": "An.merus",
                  "Total": 1.0
                },
                {
                  "Species": "An.quadrannulatus",
                  "Total": 1.0
                },
                {
                  "Species": "Non amplified",
                  "Total": 164.0
                }
              ]
            }
          ];


          for (let [sequenceNumber, dataset] of datasets.value.entries()) {
            const result = res.find(obj => obj.indicator.code === dataset.indicator.code && obj.sequenceNumber === sequenceNumber);
            dataset.data = result.data;
          }

          let formattedLabels = []

          let labels = []
          for (let item of datasets.value) {
            labels.push(...item.data.map(item => item[props.schema.labelKey]))
            formattedLabels = [...new Set(labels)];
          }


          let formattedDatasets = []

          for (let item of datasets.value) {
            let datum = {
              backgroundColor: [],
              data: []
            }

            for (let label of formattedLabels) {
              datum.data.push(searchValueByLabel(item.data, label))
            }

            datum.backgroundColor = getRandomColor()

            formattedDatasets.push(datum)

          }

          data.value = {
            labels: formattedLabels,
            datasets: formattedDatasets
          }
        }
      }
    }

    const data = ref({
      datasets: [],
      labels: []
    })

    const options = ref({
      responsive: true,
      aspectRatio: false,
      plugins: {
        legend: {
          position: props.schema.legendPosition ? props.schema.legendPosition.toLowerCase() : "top",
          labels: {
            // This more specific font property overrides the global property
            font: {
              style: props.schema.legendFontStyle ? props.schema.legendFontStyle.toLowerCase() : "normal",
              weight: props.schema.legendFontWeight ? props.schema.legendFontWeight.toLowerCase() : 'normal'
            }
          }
        },
      },
    })


    const loading = ref(false)


    const datasets = ref(null);


    const isFullScreen = ref(false)

    const parentElement = ref(null)
    const error = ref(null)

    onMounted(() => {
    })


    // eslint-disable-next-line no-unused-vars
    watch(() => props.url, (newValue, oldValue) => {
      fetchData()
    });


    const visible = ref(false)
    return {
      data,
      options,
      error,
      loading,
      datasets,
      isFullScreen,
      parentElement,
      visible,
      onIntersection(entry) {
        visible.value = entry.isIntersecting
        fetchData()
      },
      toggle: () => {
        // const element = document.documentElement; // Get the root DOM element (usually the whole page)
        const element = parentElement.value.$el; // Get the root DOM element (usually the whole page)

        if (document.fullscreenElement) {
          // If already in fullscreen, exit fullscreen
          document.exitFullscreen();
        } else {
          // If not in fullscreen, request fullscreen
          element.requestFullscreen()
              .catch(err => {
                console.error('Failed to enter fullscreen mode:', err);
              });
        }
      }
    }
  }

}

</script>
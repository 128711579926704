import * as components from "@/exports";

const JasComponentsList=components?.default;

    const install=function install(Vue){
        console.log(JasComponentsList)
        Object.keys(JasComponentsList).forEach(name=>{
            Vue.component(name,JasComponentsList[name]);

        })
    }


export {install};


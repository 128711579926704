<template>

  <q-btn @click="dialog=true">
    <q-dialog v-model="dialog" backdrop-filter="contrast(48%)" persistent class="q-pa-none">
      <q-card class="q-pa-none q-ma-none shadow-0" style="width: 600px">
        <q-card-section class="q-ma-none q-pa-xs bg-grey-2">
          <div class="row items-center no-wrap">
            <div class="col q-ml-md">
              <q-item-label class="" style="font-size: 1.0em">Filters</q-item-label>
            </div>
            <q-space></q-space>
            <div class="col-auto ">
                <q-btn flat dense round icon="close" v-close-popup />
            </div>
          </div>

        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="q-ma-none q-pa-none">
          <q-table
              no-data-label="No filters"
              no-results-label="No filters"
              dense
              square
              bordered
              :pagination="initialPagination"
              separator="cell"
              class="filter-table q-pa-none q-ma-none shadow-0"
              :rows="filters"
              :columns="columns"
              row-key="name"
          >
            <template v-slot:body-cell-key="props">
              <q-td class="q-ma-none" :props="props">
                <q-input dense outlined v-model="props.row.key" />
              </q-td>
            </template>

            <template v-slot:body-cell-value="props">
              <q-td class="q-ma-none" :props="props">
                <q-input dense outlined v-model="props.row.value" />
              </q-td>
            </template>

            <template v-slot:body-cell-operator="props">
              <q-td class="q-ma-none" :props="props">
                <q-select dense outlined options-dense :options="operators" v-model="props.row.operator"></q-select>
              </q-td>
            </template>

            <template v-slot:body-cell-actions="{props,rowIndex}">
              <q-td :props="props">
                <div class="flex flex-center justify-center">
                  <q-btn @click="onRemoveFilter(evt,rowIndex)" size="sm" padding="0 0 0 0" square flat color="negative" icon="clear"></q-btn>
                </div>
              </q-td>
            </template>
          </q-table>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-actions class="q-ma-none q-pa-xs">
          <q-btn @click="onAddFilter" no-caps color="grey-7" outline dense>Add</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-btn>


</template>

<script>


import {ref} from "vue";

export default {

  name: 'DataFilter',

  emits: ['update:modelValue'],

  props: {

    operators: {
      type: [Array, undefined, null],
      required: true
    },

    modelValue: {
      required: false,
      type: [Array, undefined, null],
      default:()=>{
        return []
      }
    },

  },


  // eslint-disable-next-line
  setup(props, {emit}) {


    const filters = ref(props.modelValue);
    const dialog=ref(false)
    const columns=ref([
      { name: 'key', label: 'KEY', field: 'key',align: 'left',style: 'width: 25%' },
      { name: 'operator', label: 'OPERATOR', field: 'operator',align: 'center',style: 'width: 45%' },
      { name: 'value', label: 'VALUE', field: 'value',align: 'left',style: 'width: 28%' },
      { name: 'actions', label: '', field: 'actions',align: 'center', style: 'width: 2%', },
    ])

    return {
      filters,
      dialog,
      columns,
      initialPagination: {
        page: 0,
        rowsPerPage: 10
        // rowsNumber: xx if getting data from a server
      },

      onAddFilter:()=>{
        filters.value.push({

        });
        emit('update:modelValue', filters.value)
      },
      onRemoveFilter(evt, index) {
        filters.value.splice(index, 1);
        emit('update:modelValue', filters.value)
      },

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>


.filter-table
  /* height or max-height is important */
  max-height: 410px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th /* bg color is important for th; just specify one */
    background-color: white

  thead tr th
    position: sticky
    z-index: 1
  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
  thead tr:first-child th
    top: 0

  /* prevent scrolling behind sticky top row on focus */
  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>
